import { reactive, computed, readonly } from 'vue'
import { useHttpCommon } from './useHttpCommon'

const state = reactive({
  refreshToken: localStorage.getItem('refresh_token') || null,
  accessToken: localStorage.getItem('access_token') || null,
  expiresAt: localStorage.getItem('expires_at') || null,
  user: JSON.parse(localStorage.getItem('user')) || null
})

export function useAuth() {
  const { HTTP_AUTH } = useHttpCommon()

  const isAuthenticated = computed(() => {
    return !!state.accessToken && new Date().getTime() < Number(state.expiresAt)
  })

  const setRefreshToken = (token) => {
    state.refreshToken = token
    localStorage.setItem('refresh_token', token)
  }

  const setAccessToken = (token) => {
    state.accessToken = token
    localStorage.setItem('access_token', token)
  }

  const setExpiresAt = (expiresIn) => {
    const expirationTime = JSON.stringify(expiresIn * 1000 + new Date().getTime())
    state.expiresAt = expirationTime
    localStorage.setItem('expires_at', expirationTime)
  }

  const setUser = (userData) => {
    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
  }

  const getUserUUID = () => {
    if (!state.accessToken) {
      return null;
    }
    const decodedJWT = decodeJWT(state.accessToken);
    return decodedJWT?.profileId || null;
  }

  function decodeJWT(token) {
    // Split the token into its three parts
    const [, payloadBase64] = token.split('.');
    
    // Decode the Base64Url encoded payload
    const payloadJson = atob(payloadBase64.replace(/-/g, '+').replace(/_/g, '/'));
    
    // Parse the JSON string
    const payload = JSON.parse(payloadJson);
    
    // Return the payload
    return payload;
  }

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      HTTP_AUTH({
        data: {
          grant_type: 'password',
          client_id: process.env.VUE_APP_ADMIN_CLIENT_ID,
          client_secret: process.env.VUE_APP_ADMIN_CLIENT_SECRET,
          scope: 'read:auth create:auth update:auth delete:auth read:diary create:diary update:diary delete:diary read:venue create:venue update:venue delete:venue read:task create:task update:task delete:task read:readings read:supplier create:supplier update:supplier delete:supplier',
          username: email,
          password: password,
        }
      })
        .then(response => {
          setExpiresAt(response.data.expires_in)
          setAccessToken(response.data.access_token)
          setRefreshToken(response.data.refresh_token)
          console.log("auth successful, isAuthenticated:", isAuthenticated.value)
          resolve()
        })
        .catch(e => {
          console.log("auth failed")
          reject(e)
        })
        .finally(() => {
          console.log("auth call completed")
        })
    })
  }

  const logout = () => {
    setRefreshToken(null)
    setAccessToken(null)
    setExpiresAt(null)
    setUser(null)
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('user')
  }

  return {
    state: readonly(state),
    isAuthenticated,
    login,
    logout,
    setRefreshToken,
    setAccessToken,
    setExpiresAt,
    setUser,
    getUserUUID,
  }
}

export const authPlugin = {
  install: (app) => {
    app.config.globalProperties.$auth = useAuth()
  }
}