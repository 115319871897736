import constants from './constants';
const { fernetTokenValidityTtlSeconds } = constants;

export default {
	nav: {
		icon: 'fa-user-cog',
		preferences: true,
		title: 'Chomp Admin',
		version: process.env.VUE_APP_VERSION
	},
	home: {
		nav: true,
		home: true,
		icon: 'fa-home',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
			title: "Find a venue",
			description: "Search for a specific venue using the search box below. Enter the venue name or any relevant keyword to filter the results and quickly locate the information you need.",
			prefill: false,
			headers: [				
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}',
					uniqueKey: true
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'Link',
					value: 'id',
					route: '/venue_link/{id}',
					icon: 'fa-key'
				},
				{
					text: 'Dashboard',
					value: 'id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/venues/{id}/dashboard',
					icon: 'fa-tachometer-alt'
				},
				{
					text: 'Plan',
					value: 'id',
					route: '/venue_plan/{id}',
					icon: 'fa-book'
				},
				{
					text: 'HubSpot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					icon: 'fa-envelope-open-text',
				},
				{
					text: 'Xero',
					value: 'billing_id',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_id}',
					icon: 'fa-search-dollar',
				},
				{
					text: 'Company',
					value: 'company_id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/companies/{company_id}',
					icon: 'fa-user-cog'
				},
				{
					text: 'Employees',
					value: 'id',
					route: '/venue_employees/{id}',
					icon: 'fa-users',
				},
				{
					text: 'Devices',
					value: 'id',
					route: '/venue_devices/{id}',
					icon: 'fa-tablet-alt'
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				}
			]
		}
	},
	companies: {
		nav: true,
		icon: "fa-building",
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?inactive=0',
			add: '/company',
			title: "Find a Company",
			description: "Search for a specific company using the search box below. Enter the company name or any relevant keyword to filter the results and quickly locate the information you need.",
			prefill: false,
			headers: [
				{
					text: 'Companies',
					value: 'name',
					route: '/company/{id}',
				},
				{
					text: 'HubSpot Company',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{id}'
				},
				{
					text: 'Company Dashboard',
					value: 'id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/companies/{id}',
					icon: 'fa-user-cog',
					uniqueKey: true
				},
				{
					text: 'Venues',
					value: 'venueCount',
					route: '/company_venues/{id}'
				},
				{
					text: 'Employees',
					value: 'employeeCount',
					route: '/company_employees/{id}'
				},
				{
					text: 'Legacy Users',
					value: 'id',
					icon: 'fa-user-lock',
					route: '/company_legacy_users/{id}'
				}
			]
		}
	},
	sales: {
		nav: true,
		title: 'Sales Reports',
		icon: 'fa-funnel-dollar',
		list: {
			items: [
				{ 
					header: 'Sales Reports',
					description: 'Reports that summarise sales activities, performance, and results over a specific period. These include data on revenue, number of units sold, customer trends, and other key performance indicators related to Chomps\'s sales efforts. Sales reports can cover various timeframes (e.g., daily, weekly, monthly, quarterly, or annually) and are used by businesses to track progress, identify trends, and make informed decisions about sales strategies.',
				},
				{
					icon: 'fa-utensils',
					title: 'Venue Sales Pipeline Report',	
					subtitle: ' A list of venues and their important sales pipeline dates',
					path: 'hubspot_venues'
				},
				{
					icon: 'fa-phone',
					title: 'Qualified Leads Report',	
					subtitle: ' A list of qualified leads that are in the sales pipeline and need to be converted',
					path: 'qualified_leads'
				},
				{
					icon: 'fa-hourglass-half',
					title: 'Trial Venue Report',	
					subtitle: ' A list of trial venues that are in the sales funnel and awaiting first payment',
					path: 'trial_venues'
				},
				{
					icon: 'fa-car-crash',
					title: 'L-Plates Report',	
					subtitle: ' A list of venues that have been created in the last 60 days and might need a little help staying between the lines.',
					path: 'l_plates_report'
				},
				{
					icon: 'fa-heartbeat',
					title: 'Live Venue Report',	
					subtitle: ' A list of venues that have had their app released and able to use the software and should be paying',
					path: 'live_venues'
				},
				{
					icon: 'fa-recycle',
					title: 'Recycled Venues Report',	
					subtitle: ' A list of venues that have been recycled from venues that we have recently lost.',
					path: 'recycled_venues'
				},
				{
					icon: 'fa-dumpster-fire',
					title: 'Lost Customer Report',	
					subtitle: ' A list of venues that have left us for more dangerous food and a severe desire to fail',
					path: 'lost_customers'
				},
				{
					icon: 'fa-comment-dollar',
					title: 'Outstanding Invoices',	
					subtitle: ' A list of unpaid invoices going back for the last 12 months',
					path: 'outstanding_invoices'
				},
				{
					icon: 'fa-file-invoice-dollar',
					title: 'Upcoming Invoices',	
					subtitle: ' A list of upcoming invoices to be sent',
					path: 'upcoming_invoices'
				},
				{
					icon: 'fa-building',
					title: 'HubSpot Companies',	
					subtitle: ' A list of companies set up in HubSpot',
					path: 'hubspot_companies'
				},
				{
					icon: 'fa-user-tie',
					title: 'Owners and Managers',	
					subtitle: ' A list of owners and managers to which we can send emails of great importance',
					path: 'owners_and_managers'
				}
			]
		}
	},
	customer_service: {
		nav: true,
		title: 'Customer Service',
		icon: 'fa-smile-wink',
		list: {
			items: [
				{ 
					header: 'Customer Service Reports',
					description: 'The assistance and support provided by a company to people who buy or use its products or services. This typically involves addressing customer inquiries, resolving issues or complaints, and ensuring overall customer satisfaction before, during, and after a purchase.',
				},
				{
					icon: 'fa-baby',
					title: 'Rookie Venue Report',	
					subtitle: ' A list of active venues that have yet to start using our software',
					path: 'rookie_venues'
				},
				{
					icon: 'fa-utensils',
					title: 'Venue Activity',	
					subtitle: ' A list of active venues and their recent activity so that we know who to call and why',
					path: 'venue_activity'
				},
				{
					icon: 'fa-archive',
					title: 'Archived Venues',
					subtitle: ' A list of all venues that are, for one reason or another, no longer a Chomp customer',
					path: 'archived_venues'
				},
				{
					icon: 'fa-trophy',
					title: 'Chompian Report',	
					subtitle: ' A list of venues that have accessed their plan within the last 7 days',
					path: 'chompian'
				},
				{
					icon: 'fa-meteor',
					title: 'Falling Star Report (Venue Inactivity)',	
					subtitle: ' A list of venues that have not accesssed their app in more than 7 and less than 30 days',
					path: 'falling_star'
				},
				{
					icon: 'fa-ship',
					title: 'Sinking Ship Report (Serious Venue Inactivity)',	
					subtitle: ' A list of venues that have not accesssed their app in more than 30 days',
					path: 'sinking_ship'
				},
				{
					icon: 'fa-dumpster-fire',
					title: 'Lost Customer Report',	
					subtitle: ' A list of venues that have left us for more dangerous food and a severe desire to fail',
					path: 'lost_customers'
				},
				{
					icon: 'fa-file-invoice-dollar',
					title: 'Credit Control ',	
					subtitle: ' A report to show the state of venues which maybe or are deliquent in payment ',
					path: 'credit_control'
				},
				{
					icon: 'fa-tablet-alt',
					title: 'Venues and Their Devices',
					subtitle: ' A list of venues and the devices the have accessed their plan in the last 2 months',
					path: '/venues_with_devices'
				},
				{
					icon: 'fa-stopwatch',
					title: 'Special Venue Report',	
					subtitle: ' A report show the state of new, trial and suspended venues',
					path: 'special_venue_activity'
				},
				{
					icon: 'fa-user-nurse',
					title: 'Legacy User Lookup',
					subtitle: " Find a legacy user in the legacy syste.",
					path: 'legacy_users'
				},	
				{
					icon: 'fa-warehouse',
					title: 'Inactive Companies',
					subtitle: ' A list of all inactive Companies',
					path: 'inactive_companies'
				},
				{
					icon: 'fa-thermometer-full',
					title: 'Active Venues with Assigned Reading Devices',
					subtitle: ' A list of active venues that have reading devices assigned to tasks',
					path: '/venues_with_reading_devices'
				},
				{
					icon: 'fa-thermometer-half',
					title: 'Unassigned Reading Devices',
					subtitle: ' A list of readings devices/sensors that are collecting data but have not been assigned to a venue',
					path: '/unassigned_reading_devices'
				},
				{
					icon: 'fa-thermometer-empty',
					title: 'Failing Loggers',
					subtitle: ' A list of assigned loggers that are failing to get current readings',
					path: '/failing_reading_devices'
				}
			]
		}
	},
	sources: {
		icon: 'fa-cloud',
		nav: true,
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
			add: '/source',
			title: 'Data Sources',
			description: 'Data sources in the food safety industry are critical for maintaining product quality and consumer safety.',
			headers: [
				{
					text: 'Source/Supplier',
					value: 'name'
				},
				{
					text: 'Loggers',
					value: 'id',
					route: '/reading_devices/{id}',
					icon: 'fa-thermometer-full'
				},
				{
					text: 'Imports',
					value: 'id',
					route: '/import_log_performance_chart/{id}',
					icon: 'fa-chart-line'
				},
				{
					text: 'Users',
					value: 'id',
					route: '/source_users/{id}',
					icon: 'fa-id-card'
				},
			]
		}
	},
	templates: {
		nav: true,
		icon: 'fa-columns',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates?orderBy=updated_at&direction=desc',
			add: '/template',
			delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			prefill: false,
			title: 'Templates',
			description: 'A library of Chomp templates',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/template/{id}',
				},
				{
					text: 'Type',
					value: 'type',
					icon: {
						'schedule': 'fa-calendar-alt',
						'inductance': 'fa-graduation-cap',
						'event': 'fa-bell',
						'frequent': 'fa-clock'					
					}
				},
				{
					text: 'Category',
					value: 'category_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Configuration',
					value: 'id',
					route: '/template_configuration/{id}',
					icon: 'fa-cog',
					uniqueKey: true
				},
				{
					text: 'Tasks',
					value: 'id',
					icon: 'fa-list',
					route: '/template_tasks/{id}'
				},
				{
					text: 'Last Updated',
					value: 'updated_at',
					convert: 'datetime'
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'inactive': 'fa-times',
						'archived': 'fa-archive'
					}
				}
			]
		}
	},
	template_configuration: {
		icon: 'fa-cog',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/templates'
		},
		list: {
			items: [
				{ 
					header: 'What would you like to do with this templates configuration?' 
				},
				{
					icon: 'fa-drafting-compass',
					title: 'Change Configuration',
					subtitle: ' Add, update and delete components, predicates and other parts of the draft configuration',
					path: '/draft_template_configuration/{id}'
				},
				{
					icon: 'fa-vial',
					title: 'Test Configuration',
					subtitle: ' Test this draft configuration in a task of a staging venue',
					path: '/test_template_configuration/{id}'
				},
				{
					icon: 'fa-copy',
					title: 'Copy a Draft Configuration',
					subtitle: ' Copy the draft configuration from another template into this draft configuration',
					path: '/clone_template_configuration/{id}'
				},
				{
					icon: 'fa-spell-check',
					title: 'Check and Publish a Configuration',
					subtitle: ' Check and report on the state of a draft configuration and publish if everything looks OK',
					path: '/check_template_configuration/{id}'
				},
				{
					icon: 'fa-code-branch',
					title: 'Version History for Configuration',
					subtitle: ' Inspect the version history of published configuration',
					path: '/versions_template_configuration/{id}'
				},
				{
					icon: 'fa-microscope',
					title: 'Advanced Draft Configuration Management',
					subtitle: ' Adjust the draft configuration using advanced tools',
					path: '/advanced_template_configuration/{id}'
				}
			]
		}
	},
	template_tasks: {
		icon: 'fa-list',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/templates'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/tasks?templateId={id}&orderBy=created_at&direction=desc',
			headers: [
				{
					text: 'Task Name',
					value: 'name',
					route: '/task/{id}',
				},
				{
					text: 'Venue',
					value: 'venue_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Latest Entries',
					value: 'venue_id',
					route: '/diary_entries_by_task/{venue_id}?task_id={id}',
					icon: 'fa-book'
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	hubspot_companies: {
		icon: "fa-building",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/companies',
			headers: [
				{
					text: 'Hubspot Companies',
					value: 'name',
					id: 'id',
					route: '/hubspot_company/{id}',
				},
				{
					text: 'Hubspot ID',
					value: 'id',		
					route: 'https://app.hubspot.com/contacts/3019751/company/{id}'
				},
				{
					text: 'Venue Count',
					value: 'venue_count'
				},
				{
					text: 'Status',
					value: 'customer_status'
				},
				{
					text: 'Business Model',
					value: 'business_model',
				},
				{
					text: 'Market Sector',
					value: 'sector'
				}
			]
		}
	},
	hubspot_venues: {
		icon: "fa-utensils",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues?withVenue=1&orderBy=sale_confirmation_date&direction=desc',
			headers: [
				{
					text: 'Venues',
					value: 'name',
					id: 'id',
					route: '/hubspot_venue/{id}',
				},
				{
					text: 'Hubspot ID',
					value: 'id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{id}'
				},
				{
					text: 'Status',
					value: 'customer_status'
				},
				{
					text: 'Recycled',
					value: 'recycled_sale',
					icon: 'fa-recycle'
				},
				{
					text: 'Sale Confirmed',
					value: 'sale_confirmation_date'
				},
				{
					text: 'App Released',
					value: 'live_date'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'date',
						to: 'isodate'
					}
				},
				{
					text: 'Days To Use',
					value: 'ttu'
				},
				{
					text: 'Subscription Type',
					value: 'subscription_type'
				},
				{
					text: 'Business Model',
					value: 'business_model',
				},
				{
					text: 'Market Sector',
					value: 'sector'
				}
			]
		}
	},
	hubspot_company: {
		icon: "fa-building",
		subtitle: {
			value: 'HubSpot Companies',
			link: '/hubspot_companies'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/company/{id}',
			design: [
				{
					label: 'Company Name',
					value: 'name',
					readonly: true
				},
				{
					label: 'Status',
					value: 'customer_status',
					readonly: true
				},
				{
					label: 'Venues',
					value: 'venue_count',
					readonly: true					
				},
				{
					label: 'Business Model',
					value: 'business_model',
					readonly: true
				},
				{
					label: 'Market Sector',
					value: 'sector',
					readonly: true
				}
			]
		}
	},
	qualified_leads: {
		icon: "fa-phone",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			title: "Qualified Leads",
			description: "These NEW venues are Hot to Trot!",
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=new&with_hubspot=1&has_parent=0',
			headers: [
				{
					text: 'Venue',
					value: 'name',
					id: 'id',
					route: '/release_app/{id}',
				},
				{
					text: 'Company',
					value: 'company_id',
					route: '/company/{company_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Hubspot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					icon: 'fa-envelope-open-text'
				},
				{
					text: 'Price Code',
					value: 'hubspot',
					name: 'price_code',
					route: '/qualified_lead/{id}',
				},
				{
					text: 'Sales Status',
					value: 'hubspot',
					name: 'customer_status',
				},
				{
					text: 'Sale Confirmation Date',
					value: 'hubspot',
					name: 'sale_confirmation_date'
				}
			]
		}
	},
	trial_venues: {
		icon: "fa-hourglass-half",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=trial&with_hubspot=1&has_parent=0',
			headers: [
				{
					text: 'Venues',
					value: 'name',
					id: 'id',
					route: '/hubspot_venue/{id}',
				},
				{
					text: 'Hubspot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{id}',
					icon: 'fa-envelope-open-text'
				},
				{
					text: 'Price Code',
					value: 'hubspot',
					key: 'price_code',
					name: 'price_code'
				},
				{
					text: 'Activity Rating',
					value: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'Sale Confirmation Date',
					value: 'hubspot',
					name: 'sale_confirmation_date',
				},
				{
					text: 'Sales Status',
					value: 'hubspot',
					name: 'customer_status',
				},
				{
					text: 'App Release Date',
					value: 'hubspot',
					name: 'live_date',
				}	
			]
		}
	},
	l_plates_report: {
		icon: "fa-car-crash",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?created_since=5184000&orderBy=created_at&direction=desc',
			headers: [
				{
					text: 'Venue',
					value: 'name',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					route: '/company/{company_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'HubSpot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					icon: 'fa-envelope-open-text'
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'Xero',
					value: 'billing_id',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_id}',
					icon: 'fa-search-dollar',
				},
				{
					text: 'Plan',
					value: 'id',
					route: '/venue_plan/{id}',
					icon: 'fa-book'
				},
				{
					text: 'Recycled',
					value: 'recycled_sale',
					icon: 'fa-recycle'
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	live_venues: {
		icon: "fa-heartbeat",
		subtitle: {
			value: 'Sales Reports',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=trial,active,suspended,blocked&with_hubspot=1&has_parent=0',
			headers: [
				{
					text: 'Venue',
					value: 'name',
					id: 'id',
					route: '/venue/{id}',
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'HubSpot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					icon: 'fa-envelope-open-text'
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						new: 'fa-exclamation',
						training: 'fa-graduation-cap',
						trial: 'fa-stopwatch',
						active: 'fa-check',
						suspended: 'fa-hand-paper',
						blocked: 'fa-comment-dollar',
						archived:' fa-archive',
					}
				},
				{
					text: 'Activity Rating',
					value: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'App Released',
					value: 'hubspot',
					name: 'live_date',
					convert: 'date'
				},
				{
					text: 'HubSpot Status',
					value: 'hubspot',
					name: 'customer_status'
				}
			]
		}
	},
	release_app: {
		icon: "fa-phone",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/qualified_leads'
		},
		steps: [
			{
				title: 'Validate Company and Venue',
				form: {
					url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
					patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
					design: [
						{
							label: 'Hubspot Venue',
							value: 'hubspot_company_id',
							type: 'autocomplete',
							autocomplete: {
								text: 'name',
								value: 'id',
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues',
							}
						},
						{
							label: 'Chomp Venue Name',
							value: 'name',
						},
						{
							label: 'Company',
							value: 'company_id',
							type: 'autocomplete',
							autocomplete: {
								text: 'name',
								value: 'id',
								clearable: true,
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
							}
						},
						{
							label: 'Country',
							value: 'country',
							type: 'autocomplete',
							autocomplete: {
								text: 'country_name',
								value: 'country_code',
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/countries'
							}
						},
						{
							label: 'Timezone',
							value: 'timezone',
							type: 'autocomplete',
							autocomplete: {
								text: 'zone_name',
								value: 'zone_name',
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/time_zones'
							}
						}
					]
				}
			},
			{
				title: 'Setup the Plan',
				form: {
					url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
					patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
					design: [
						{
							label: 'Copy Tasks from a Template Venue',
							value: 'template_venue_id',
							type: 'autocomplete',
							autocomplete: {
								name: 'venue',
								text: 'name',
								value: 'id',
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?is_template=1'
							}
						},
						{
							label: 'Copy Suppliers?',
							value: 'copy_suppliers',
							type: 'toggle',
							showIf: {
								'name': 'template_venue_id',
								'operator': 'notEmpty'
							}
						},
						{
							label: 'Parent Venue',
							value: 'parent_venue_id',
							type: 'autocomplete',
							autocomplete: {
								text: 'name',
								value: 'id',
								id: 'company_id',
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/venues'
							}
						}
					]
				}
			},
			{
				title: 'Invite Managers and Owners',
				form: {
					url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}?with_hubspot=1',
					post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/invite',
					design: [
						{
							label: 'Venue',
							value: 'id',
							name: 'venueId',
							type: 'banner'
						},
						{
							label: 'Current Employee List',
							value: 'id',
							name: 'employee_list',
							type: 'list',
							readonly: true,
							lookup: {
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/employees',
								return: {
									'name': 'email',
									'value': 'id'
								}
							},
							showIf: {
								'name': 'employee_list',
								'operator': 'notEmpty'
							}
						},
						{
							label: 'Invite HubSpot Contacts to be Managers/Owners',
							value: 'hubspot',
							key: 'contacts',
							name: 'email',
							type: 'list',
							listNameKey: 'email',
							listValueKey: 'email'
						},
						{
							label: 'Invite New Manager/Owner by Email',
							value: 'email',
						}
					]
				}
			},
			{
				title: 'Billing Contacts and Invoicing',
				form: {
					url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}?with_hubspot=1',
					post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/billing',
					design: [
						{
							label: 'Xero Contact',
							value: 'billing_id',
							type: 'autocomplete',
							autocomplete: {
								name: 'billing_id',
								text: 'name',
								value: 'contactID',
								icon: 'fa-piggy-bank',
								clearable: true,
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contacts?all=1',
							},
							showIf: {
								'name': 'hubspot_billing_contact',
								'operator': 'empty'
							}
						},
						{
							label: 'Legal Entity Override',
							value: 'legal_entity',
							showIf: {
								'name': 'billing_id',
								'operator': 'empty'
							}
						},
						{
							label: 'Create a Xero Billing Contact from a HubSpot Primary Account Contact',
							value: 'hubspot',
							key: 'billing_contacts',
							name: 'hubspot_billing_contact',
							type: 'list',
							listNameKey: 'email',
							listValueKey: 'id',
							showIf: {
								'name': 'billing_id',
								'operator': 'empty'
							}
						},
						{
							label: 'Subscription/Price Code',
							value: 'hubspot',
							key: 'price_code',
							name: 'price_code',
							type: 'autocomplete',
							autocomplete: {
								text: 'fullName',
								value: 'code',
								icon: 'fa-barcode',
								clearable: true,
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/priceCodes',
							}
						}
					]
				}
			},
			{
				title: 'Release the App!',
				form: {
					url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}?with_hubspot=1',
					post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/release',
					design: [
						{
							label: 'Invoice Due Date',
							value: 'invoice_due_date',
							type: 'date'
						},
						{
							label: 'RELEASE THE APP!',
							value: 'id',
							type: 'button',
							post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/release',
							payload: '{ "invoice_due_date": "<invoice_due_date>" }',
							showIf: {
								'name': [
									'billing_id',
									'price_code',
									'invoice_due_date'
								],
								'operator': 'notEmpty'
							}
						}
					]
				}
			}
		]
	},
	qualified_lead: {
		icon: "fa-phone",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/qualified_leads'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}?with_hubspot=1',
			patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			design: [
				{
					label: 'Hubspot Venue',
					value: 'hubspot_company_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues',
					}
				},
				{
					label: 'Chomp Venue Name',
					value: 'name',
				},
				{
					label: 'Company',
					value: 'company_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
					}
				},
				{
					label: 'Country',
					value: 'country_code',
					type: 'autocomplete',
					autocomplete: {
						text: 'country_name',
						value: 'country_code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/countries'
					}
				},
				{
					label: 'Timezone',
					value: 'timezone',
					type: 'autocomplete',
					autocomplete: {
						text: 'zone_name',
						value: 'zone_name',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/time_zones'
					}
				},
				{
					label: 'Xero Contact',
					value: 'billing_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'billing_id',
						text: 'name',
						value: 'contactID',
						icon: 'fa-piggy-bank',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contacts',
					}
				},
				{
					label: 'Legal Entity',
					value: 'legal_entity',
					showIf: {
						'name': 'billing_id',
						'operator': 'empty'
					}
				},
				{
					label: 'HubSpot Billing Contact',
					value: 'hubspot',
					key: 'billing_contacts',
					name: 'hubspot_billing_contacts',
					type: 'table',
					headers: [
						{
							text: 'First Name', 
							value: 'firstname' 
						},
						{
							text: 'Last Name', 
							value: 'lastname' 
						},
						{
							text: 'Email', 
							value: 'email' 
						}
					],
					showIf: {
						'name': 'billing_id',
						'operator': 'empty'
					}
				},
				{
					label: 'Subscription/Price Code',
					value: 'hubspot',
					key: 'price_code',
					name: 'price_code',
					type: 'autocomplete',
					autocomplete: {
						value: 'code',
						icon: 'fa-barcode',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/priceCodes',
					}
				},
				{
					label: 'Invoice Due Date',
					value: 'invoice_due_date',
					type: 'date'
				},
				{
					label: 'Copy Tasks from a Template Venue',
					value: 'template_venue_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'venue',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?is_template=1'
					}
				},
				{
					label: 'Copy Suppliers?',
					value: 'copy_suppliers',
					type: 'toggle',
					showIf: {
						'name': 'template_venue_id',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Parent Venue',
					value: 'parent_venue_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						id: 'company_id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/venues'
					}
				},
				{
					label: 'RELEASE THE APP!',
					value: 'release_date',
					type: 'button',
					post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/release',
					showIf: {
						'name': 'release_date',
						'operator': 'empty'
					}
				}
			]
		}
	},
	hubspot_venue: {
		icon: "fa-utensils",
		subtitle: {
			value: 'HubSpot Venues',
			link: '/hubspot_venues'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/company/{id}',
			design: [
				{
					label: 'Company Name',
					value: 'name',
					readonly: true
				},
				{
					label: 'Status',
					value: 'customer_status',
					readonly: true
				},
				{
					label: 'Sale Confirmation Date',
					value: 'sale_confirmation_date',
					readonly: true
				},
				{
					label: 'App Release date',
					value: 'live_date',
					readonly: true
				},
				{
					label: 'Time to Use',
					value: 'ttu',
					readonly: true
				},
				{
					label: 'Subscription Anniversary',
					value: 'subscription_anniversary',
					readonly: true
				},
				{
					label: 'Business Model',
					value: 'business_model',
					readonly: true
				},
				{
					label: 'Market Sector',
					value: 'sector',
					readonly: true
				},
			]
		}
	},
	owners_and_managers: {
		title: 'Owners and Managers',
		icon: 'fa-user-tie',
		subtitle: {
			value: 'Sales and Marketing',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/legacy/users?roles[]=OWNER',
			headers: [
				{
					text: 'Email',
					value: 'email',
				},
				{
					text: 'First Name',
					value: 'first_name',
				},
				{
					text: 'Last Name',
					value: 'last_name',
				},
				{
					text: 'Role',
					value: 'roles',
				}
			]		
		}
	},
	venues_with_reading_devices: {
		icon: "fa-thermometer-full",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/venues',
			headers: [
				{
					text: 'Venue',
					value: 'id',
					route: '/venue_reading_devices/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Dashboard Active',
					value: 'has_reading_devices',
					route: '/venue/{id}',
					icon: 'fa-check-square'
				}
			]
		}	
	},
	import_log_performance: {
		icon: 'fa-chart-line',
		subtitle: {
			value: 'Admin',
			link: '/admin'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
			headers: [
				{
					text: 'Source/Supplier',
					value: 'name',
					id: 'id',
					route: '/import_log_performance_chart/{id}'
				}
			]
		}
	},
	import_log_performance_chart: {
		icon: 'fa-chart-line',
		subtitle: {
			value: 'Import Log Performance',
			link: '/import_log_performance'
		},
		graph: {
			title: "Readings Import Performance Chart",
			type: "LineChart",
			count: {
				"default": 10,
				"min": 1,
				"max": 100,
				"label": "Days"
			},
			data: [
				{
					legend: 'Date',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/importlog?days={count}&source={id}',
					parse_rule: 'created_at'
				},
				{
					legend: 'New Readings (#)',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/importlog?days={count}&source={id}',
					parse_rule: 'readings'
				},
				{
					legend: 'Import Duration (s)',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/importlog?days={count}&source={id}',
					parse_rule: 'duration'
				}
			]
		}
	},
	import_log: {
		icon: "fa-thermometer-full",
		subtitle: {
			value: 'Admin',
			link: '/admin'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/importlog?days=30',
			headers: [
				{
					text: 'Log ID',
					value: 'id',
				},
				{
					text: 'New Devices(#)',
					value: 'devices',
				},
				{
					text: 'New Readings(#)',
					value: 'readings',
				},
				{
					text: 'Duration(s)',
					value: 'duration',
				},
				{
					text: 'Source',
					value: 'source_id',
					id: 'source_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	rookie_venues: {
		icon: "fa-baby",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=new,trial,active&is_rookie=1&with_hubspot=1&is_template=0&has_parent=0&with_staff_count=1',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						new: 'fa-exclamation',
						training: 'fa-graduation-cap',
						trial: 'fa-stopwatch',
						active: 'fa-check',
						suspended: 'fa-hand-paper',
						blocked: 'fa-comment-dollar',
						archived:' fa-archive',
					}
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'Subscription Type',
					value: 'hubspot',
					key: 'subscription_type',
					name: 'subscription_type'
				},
				{
					text: 'Anniversary Month',
					value: 'hubspot',
					key: 'subscription_anniversary_month',
					name: 'subscription_anniversary_month'
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Employees',
					value: 'employees_count',
					id: 'id',
					route: '/venue_employees/{id}',
				},
				{
					text: 'Staff',
					value: 'staff_count',
				},
				{
					text: 'Documents',
					value: 'documents_count',
					id: 'id',
					route: '/venue_documents/{id}',
				}
			]
		}	
	},
	venue_activity: {
		icon: "fa-running",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=active&is_rookie=0',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
				},
				{
					text: 'Activity Rating',
					value: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	chompian: {
		icon: "fa-trophy",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=new,trial,active,suspended&is_rookie=0&connected_since=604800',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	falling_star: {
		icon: "fa-meteor",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=active&is_rookie=0&not_connected_since=604800&connected_since=2592000',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	outstanding_invoices: {
		icon: "fa-comment-dollar",
		subtitle: {
			value: 'Sales',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/invoices?status=outstanding&orderBy=dueDate&direction=asc',
			headers: [			
				{
					text: 'Billing Contact',
					value: 'name',
					route: '/account_venues/{contactID}'
				},
				{
					text: 'Reference',
					value: 'reference'
				},
				{
					text: 'Currency',
					value: 'currencyCode'
				},
				{
					text: 'Sub Total',
					value: 'subTotal',
					convert: 'currency'
				},
				{
					text: 'Payment Method',
					value: 'paymentType'
				},
				{
					text: 'Due Date',
					value: 'dueDate',
					convert: 'date'
				},
				{
					text: 'View Invoice',
					value: 'invoiceID',
					icon: 'fa-external-link-alt',
					route: 'https://go.xero.com/app/!jSnWd/invoicing/view/{invoiceID}'
				}
			]
		}
	},
	upcoming_invoices: {
		icon: "fa-file-invoice-dollar",
		subtitle: {
			value: 'Sales',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/invoices?orderBy=nextScheduledDate&direction=asc',
			headers: [
				{
					text: 'Billing Contact',
					value: 'name',
					route: '/account_venues/{contactID}'
				},
				{
					text: 'Reference',
					value: 'reference'
				},
				{
					text: 'Next Scheduled',
					value: 'nextScheduledDate'
				},
				{
					text: 'Invoice Status',
					value: 'status',
				},
				{
					text: 'Sub Total',
					value: 'subTotal'
				},
				{
					text: 'Currency',
					value: 'currencyCode'
				},
				{
					text: 'Payment Method',
					value: 'paymentType'
				},
				{
					text: 'Author',
					value: 'authors'
				},
				{
					text: 'View Invoice',
					value: 'repeatingInvoiceID',
					icon: 'fa-external-link-alt',
					route: 'https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID={repeatingInvoiceID}&type=AR'
				}
			]
		}
	},
	account_venues: {
		icon: "fa-comment-dollar",
		subtitle: {
			value: 'Sales',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?billing_id={id}',
			headers: [			
				{
					text: 'Venue',
					value: 'name',
					route: '/venue/{id}'
				},
				{
					text: 'Account',
					value: 'billing_id',
					name: 'account',
					route: '/venue_account/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contact/{billing_id}',
						search: 'contactID',
						return: 'name'
					}
				},
				{
					text: 'Billing Contact',
					value: 'billing_id',
					name: 'contact',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contact/{billing_id}',
						search: 'contactID',
						return: 'email'
					}
				}
			]
		}
	},
	sinking_ship: {
		icon: "fa-ship",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?with_company=1&status=active&is_rookie=0&not_connected_since=2592000',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Venue Status',
					value: 'status',
				},
				{
					text: 'Company Status',
					value: 'company',
					key: 'inactive',
					icon: {
						0: 'fa-check',
						1: 'fa-times',
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	recycled_venues: {
		icon: "fa-recycle",
		subtitle: {
			value: 'Sales',
			link: '/sales'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues?withVenue=1&recycled=1&orderBy=subscription_end_date__fsa&direction=desc',
			headers: [
				{	text: 'Venue',
					value: 'name'
				},
				{
					text: 'Status',
					value: 'venue',
					key: 'status',
					name: 'status',
					icon: {
						new: 'fa-exclamation',
						training: 'fa-graduation-cap',
						trial: 'fa-stopwatch',
						active: 'fa-check',
						suspended: 'fa-hand-paper',
						blocked: 'fa-comment-dollar',
						archived:' fa-archive',
					}
				},
				{
					text: 'Activity Rating',
					value: 'venue',
					key: 'activity',
					name: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'App Released',
					value: 'live_date',
					convert: 'date'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'date',
						to: 'isodate'
					}
				},
				{
					text: 'End Date',
					value: 'subscription_end_date__fsa',
					convert: 'date'
				},
				{
					text: 'Easy Come, Easy Go!',
					value: 'lost_customer_reason'
				},
				{
					text: 'HubSpot',
					value: 'hs_object_id',
					icon: 'fa-envelope-open-text',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hs_object_id}',
				},
				{
					text: 'Xero',
					value: 'billing_contact_id',
					icon: 'fa-search-dollar',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_contact_id}',
				}
			]
		}	
	},
	lost_customers: {
		icon: "fa-dumpster-fire",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues?withVenue=1&lost=1&orderBy=subscription_end_date__fsa&direction=desc',
			headers: [
				{	text: 'Venue',
					value: 'name'
				},
				{
					text: 'Status',
					value: 'venue',
					key: 'status',
					name: 'status',
					icon: {
						new: 'fa-exclamation',
						training: 'fa-graduation-cap',
						trial: 'fa-stopwatch',
						active: 'fa-check',
						suspended: 'fa-hand-paper',
						blocked: 'fa-comment-dollar',
						archived:' fa-archive',
					}
				},
				{
					text: 'Activity Rating',
					value: 'venue',
					key: 'activity',
					name: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'App Released',
					value: 'live_date',
					convert: 'date'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'date',
						to: 'isodate'
					}
				},
				{
					text: 'End Date',
					value: 'subscription_end_date__fsa',
					convert: 'date'
				},
				{
					text: 'Reason',
					value: 'lost_customer_reason'
				},
				{
					text: 'HubSpot',
					value: 'hs_object_id',
					icon: 'fa-envelope-open-text',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hs_object_id}',
				},
				{
					text: 'Xero',
					value: 'billing_contact_id',
					icon: 'fa-search-dollar',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_contact_id}',
				}
			]
		}	
	},
	credit_control: {
		icon: "fa-file-invoice-dollar",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?credit_control=1',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Status',
					value: 'status',
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	special_venue_activity: {
		icon: "fa-stopwatch",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=new,trial,suspended&is_rookie=0',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					id: 'company_id',
					route: '/company/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
				},
				{
					text: 'Activity Rating',
					value: 'activity',
					icon: {
						'Rookie': 'fa-baby',
						'Chompian': 'fa-trophy',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb',
						'Unknown': 'fa-ban'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}	
	},
	venues_with_devices: {
		icon: "fa-tablet-alt",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/devices/venues',
			headers: [
				{
					text: 'Venue',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Minimum Version',
					value: 'minimum_version'
				},
				{
					text: 'Devices',
					value: 'devices_count',
					id: 'id',
					route: '/venue_devices/{id}'
				},
				{
					text: 'Last Version',
					value: 'app_version'
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				}
			]
		}
	},
	archived_venues: {
		icon: "fa-archive",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=archived&credit_control=0',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					route: '/company/{company_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1&inactive=1&active=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'App Version',
					value: 'app_version'
				},
				{
					text: 'Diary',
					value: 'id',
					route: '/diary/{id}',
					icon: 'fa-book'
				}
			]
		}	
	},
	inactive_companies: {
		icon: "fa-warehouse",
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?inactive=1',
			delete: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
			headers: [
				{
					text: 'Companies',
					value: 'name',
					id: 'id',
					route: '/company/{id}'
				},
				{
					text: 'Venues',
					value: 'venueCount',
					id: 'id',
					route: '/company_venues/{id}'
				},
				{
					text: 'Employees',
					value: 'employeeCount',
					id: 'id',
					route: '/company_employees/{id}'
				}
			]
		}
	},
	unassigned_reading_devices: {
		icon: 'fa-thermometer-empty',
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/devices?assigned=0',
			headers: [
				{
					text: 'Reading Device',
					value: 'name',
					id: 'id',
					route: '/reading_device/{id}'
				},
				{
					text: 'Alias',
					value: 'alias'
				},
				{
					text: 'Source Type',
					value: 'source_user_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Last Reading Timestamp',
					value: 'id',
					name: 'timestamp',
					route: '/reading/{id}',
					convert: 'datetime',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'timestamp'
					}
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'inactive': 'fa-times',
						'deleted': 'fa-ban'
					}
				}
			]
		}
	},
	failing_reading_devices: {
		icon: 'fa-thermometer-empty',
		subtitle: {
			value: 'Customer Service',
			link: '/customer_service'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
			headers: [
				{
					text: 'Source/Supplier',
					value: 'name',
					id: 'id',
					route: '/failing_reading_devices_by_source/{id}'
				}
			]
		}
	},
	failing_reading_devices_by_source: {
		icon: 'fa-thermometer-empty',
		subtitle: {
			value: 'Back to Source List',
			link: '/failing_reading_devices'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/devices?status=active&assigned=1&not_since=86400&sourceUserId={id}',
			headers: [
				{
					text: 'Reading Device',
					value: 'name',
					id: 'id',
					route: '/reading_device/{id}'
				},
				{
					text: 'Alias',
					value: 'alias'
				},
				{
					text: 'Venue',
					value: 'venue_id',
					id: 'venue_id',
					route: '/venue/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Last Reading (C)',
					value: 'id',
					name: 'last_reading',
					route: '/reading/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'average'
					}
				},
				{
					text: 'Last Reading Timestamp',
					value: 'id',
					name: 'timestamp',
					route: '/reading/{id}',
					convert: 'datetime',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'timestamp'
					}
				}
			]
		}
	},
	admin: {
		nav: true,
		icon: 'fa-hat-wizard',
		list: {
			items: [
				{ 
					header: 'Chomp Administration',
					description: 'Central hub for system management',
				},
				{
					icon: 'fa-chart-line',
					title: 'Loggers Import Performance Graph',
					subtitle: ' A graph of recent import data performance',
					path: '/import_log_performance'
				},
				{
					icon: 'fa-database',
					title: 'Loggers Import Activity',
					subtitle: ' A list of recent import activity',
					path: '/import_log'
				},
				{
					icon: 'fa-shield-alt',
					title: 'Security',
					subtitle: 'Security related management tools',
					path: '/security'
				},
				{
					icon: 'fa-user-nurse',
					title: 'Migrate Legacy Users',
					subtitle: ' A list of users that need to migrate to the new Profile/Employee system',
					path: '/legacy_users'
				},	
				{
					icon: 'fa-book',
					title: 'Documentation',
					subtitle: 'Helpful links to Chomp techincal documentation',
					path: '/documentation'
				},
			]
		}
	},
	company: {
		icon: 'fa-building',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}?with_venues=0',
			add: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company',
			patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
			design: [
				{
					label: 'Name',
					value: 'name'
				},
				{
					label: 'Status',
					value: 'inactive',
					noadd: true,
					type: 'list',
					list: [
						{
							'name': 'Inactive',
							'value': 1,
						},
						{
							'name': 'Active',
							'value': 0,
						}
					]
				},
				{
					label: 'Hubspot Company',
					value: 'hubspot_company_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'hubspot_company_id',
						text: 'name',
						value: 'id',
						icon: 'fa-building',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/companies',
					}
				},
				{
					label: 'Plan Type',
					value: 'config',
					name: 'plan_type',
					key: 'plan_type',
					type: 'list',
					default: 'simple_safe_suitable',
					list: [
						{
							'name': 'Simply Safe and Suitable (SSS)',
							'value': 'simple_safe_suitable',
						},
						{
							'name': 'Food Control Plan (FCP)',
							'value': 'fcp'
						}
					]
				},
				{
					label: 'Training',
					value: 'config',
					name: 'training',
					key: 'training',
					type: 'list',
					default: 1,
					list: [
						{
							'name': 'yes',
							'value': 1,
						},
						{
							'name': 'no',
							'value': 0,
						}
					]
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	company_venues: {
		icon: 'fa-utensils',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
			value: 'name',
			link: '/companies'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/venues?orderBy=app_last_connected&direction=desc',
			add: '/venue',
			headers: [
				{
					text: 'Venue ID',
					value: 'id',
				},
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}'
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						new: 'fa-exclamation',
						training: 'fa-graduation-cap',
						trial: 'fa-stopwatch',
						active: 'fa-check',
						suspended: 'fa-hand-paper',
						blocked: 'fa-comment-dollar',
						archived:' fa-archive',
					}
				},
				{
					text: 'Activity Rating',
					value: 'activity',
					icon: {
						'Chompian': 'fa-trophy',
						'Rookie': 'fa-baby',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb'
					}
				},
				{
					text: 'Start Date',
					value: 'start_date',
					convert: {
						from: 'timestamp',
						to: 'date'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				},
				{
					text: 'App Version',
					value: 'app_version'
				},
				{
					text: 'Template',
					value: 'is_template',
					icon: {
						1: 'fa-columns'
					}
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Request Payment',
					value: 'request_payment',
					icon: {
						1: 'fa-hand-holding-usd'
					}
				}
			]
		}
	},
	company_employees: {
		icon: 'fa-id-badge',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
			value: 'name',
			link: '/companies'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/employees?with_profile=1&orderBy=created_at&direction=desc',
			add: '/employee',
			delete: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{id}',
			headers: [
				{
					text: 'Email',
					value: 'email',
					route: '/employee/{id}',
				},
				{
					text: 'Name',
					value: 'profile',
					key: 'name',
					name: 'name',
					route: '/profile/{profile_id}',
				},
				{
					text: 'Profile Email',
					value: 'profile',
					key: 'email',
					name: 'profile_email',
					route: '/profile/{profile_id}',
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'suspended': 'fa-ban',
						'archived': 'fa-archive'
					}
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	company_legacy_users: {
		icon: 'fa-id-badge',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
			value: 'name',
			link: '/companies'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/users',
			headers: [
				{
					text: 'Email',
					value: 'email'
				},
				{
					text: 'Old Email',
					value: 'old_email'
				},
				{
					text: 'First Name',
					value: 'first_name'
				},
				{
					text: 'Last Name',
					value: 'last_name'
				},
				{
					text: 'Migrated',
					value: 'migrated',
					icon: {
						1: 'fa-check'
					}
				}
			]
		}
	},
	venues: {
		icon: "fa-utensils",
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?company_id={id}',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/venue/{id}',
					uniqueKey: true
				},
				{
					text: 'Account',
					value: 'id',
					route: '/venue_account/{id}',
					icon: 'fa-coins',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					text: 'Dashboard',
					value: 'id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/venues/{id}/dashboard',
					icon: 'fa-tachometer-alt'
				},
				{
					text: 'HubSpot',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					icon: 'fa-envelope-open-text',
				},
				{
					text: 'Xero',
					value: 'billing_id',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_id}',
					icon: 'fa-search-dollar',
				},
				{
					text: 'Company',
					value: 'company_id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/companies/{company_id}',
					icon: 'fa-user-cog'
				},
				{
					text: 'Plan',
					value: 'id',
					route: '/venue_plan/{id}',
					icon: 'fa-book'
				},
				{
					text: 'Link',
					value: 'id',
					route: '/venue_link/{id}',
					icon: 'fa-key'
				},
				{
					text: 'Invoices',
					value: 'id',
					route: '/invoices/{id}',
					icon: 'fa-piggy-bank'
				},				
				{
					text: 'Employees',
					value: 'id',
					route: '/venue_employees/{id}',
					icon: 'fa-users',
				},
				{
					text: 'Devices',
					value: 'id',
					route: '/venue_devices/{id}',
					icon: 'fa-tablet-alt'
				},
				{
					text: 'Loggers',
					value: 'has_reading_devices',
					id: 'id',
					route: '/venue_reading_devices/{id}',
					icon: {
						1: 'fa-thermometer-half'
					}
				},
				{
					text: 'Last Connected',
					value: 'app_last_connected',
					convert: {
						from: 'datetime',
						to: 'datetime'
					}
				}
			]
		}
	},
	venue: {
		icon: "fa-utensils",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/home'
		},
		list: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			items: [
				{ 
					header: 'Venue Details',
					description: 'Select from the options below to interact with this venue'
				},
				{
					icon: 'fa-edit',
					title: 'Edit Venue Details',
					subtitle: 'Use this form to adjust the settings for a venue',
					path: '/edit_venue/{id}',
				},
				{
					icon: 'fa-book',
					title: 'Plan',
					subtitle: 'From here you can access a venues plan, such as tasks, diaries, suppliers, documents, etc.',
					path: '/venue_plan/{id}',
				},
				{
					icon: 'fa-tachometer-alt',
					title: 'Dashboard',
					subtitle: 'Open the dashboard into a new tab',
					path: process.env.VUE_APP_SETUP_BASE_URL + '/venues/{id}/dashboard'
				},
				{
					icon: 'fa-key',
					title: 'Venue Links',
					subtitle: 'Use this page to get use or share a link to a venue\'s plan',
					path: '/venue_link/{id}'
				},
				{
					icon: 'fa-coins',
					title: 'Account',
					subtitle: 'Check out the status of past paid, outstanding and future invoices for this venue',
					path: '/venue_account/{id}',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				},
				{
					icon: 'fa-users',
					title: 'Employees',
					subtitle: 'Manage the employees that have access to this venue\'s plan',
					path: '/venue_employees/{id}'
				},
				{
					icon: 'fa-tablet-alt',
					title: 'Devices',
					subtitle: 'Find out information about the devices that have accessed this venue\'s plan recently.',
					path: '/venue_devices/{id}'
				},
				{
					icon: 'fa-thermometer-half',
					title: 'Loggers',
					subtitle: 'Find out about the loggers that are being used with the tasks in this plan',
					path: '/venue_reading_devices/{id}',
				},
				{
					icon: 'fa-dolly',
					title: 'Move Venue to a Different Company',
					subtitle: 'Sometimes venues need to move to anothe company after a sale or for some other business reason',
					path: '/move_venue/{id}',
				},
				{
					icon: 'fa-envelope-open-text',
					title: 'HubSpot',
					subtitle: 'Access this venue in Hubspot in order to check out the state of the venue',
					path: 'https://app.hubspot.com/contacts/3019751/company/{hubspot_company_id}',
					showIf: {
						'name': 'hubspot_company_id',
						'operator': 'notEmpty'
					}
				},
				{
					icon: 'fa-search-dollar',
					title: 'Xero',
					subtitle: 'Access this venue in Xero in order to find out more about the invoicing to this venue',
					path: 'https://go.xero.com/app/!jSnWd/contacts/contact/{billing_id}',
					showIf: {
						'name': 'billing_id',
						'operator': 'notEmpty'
					}
				}
			]
		} 
	},
	venue_plan: {
		icon: "fa-book",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		list: {
			items: [
				{ 
					header: 'Venue Plan' 
				},
				{
					icon: 'fa-book',
					title: 'Diary',
					path: '/diary/{id}',
				},
				{
					icon: 'fa-list',
					title: 'Tasks',	
					path: '/tasks/{id}',
				},
				{
					icon: 'fa-boxes',
					title: 'Archived Tasks',	
					path: '/archived_tasks/{id}',
				},
				{
					icon: 'fa-truck',
					title: 'Suppliers',	
					path: '/venue_suppliers/{id}',
				},
				{
					icon: 'fa-file-pdf',
					title: 'Documents',	
					path: '/venue_documents/{id}',
				},
				{
					icon: 'fa-id-badge',
					title: 'Employees',	
					path: '/venue_employees/{id}'
				},
				{
					icon: 'fa-door-closed',
					title: 'Closed Days',	
					path: '/closed_days/{id}'
				},
				{
					icon: 'fa-columns',
					title: 'Segments',
					path: '/venue_segments/{id}',
				},
				{
					icon: 'fa-key',
					title: 'Get or send a link to this plan',	
					path: '/venue_link/{id}'
				},
				{
					icon: 'fa-user-plus',
					title: 'Invite an Employee to this plan',	
					path: '/invite_to_venue/{id}'
				}
			]
		}
	},
	new_venue: {
		icon: "fa-utensils",
		subtitle: {
			value: 'Home',
			link: '/home'
		},
		form: {
			add: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/new',
			design: [
				{
					label: 'Hubspot Venue',
					value: 'hubspot_company_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'hubspot_company_id',
						text: 'name',
						value: 'id',
						icon: 'fa-utensils',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues',
					}
				},
				{
					label: 'Venue Name',
					value: 'name',
				},
				{
					label: 'Company',
					value: 'company_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'company',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
					}
				},
				{
					label: 'Timezone',
					value: 'timezone',
					type: 'autocomplete',
					autocomplete: {
						text: 'zone_name',
						value: 'zone_name',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/time_zones'
					}
				},
				{
					label: 'Copy Tasks from a Template Venue',
					value: 'template_venue_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'venue',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?is_template=1'
					}
				},
				{
					label: 'Copy Suppliers?',
					value: 'copy_suppliers',
					type: 'toggle'
				}
			]
		}
	},
	edit_venue: {
		icon: "fa-utensils",
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}?with_journal=1',
			patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			design: [
				{
					label: 'Name',
					value: 'name',
					required: true,				// Optional	config property to trigger validation on submission
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Hubspot Venue',
					value: 'hubspot_company_id',
					required: false,				// Optional	config property to trigger validation on submission
					cols: 12,								// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						name: 'hubspot_company_id',
						text: 'name',
						value: 'id',
						icon: 'fa-utensils',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues',
						suggestions: {					// Use a value returned in the autocomplete to populate another control
							sourceKey: 'name', 		// The key of the autocomplete item which we want to emit to populate a sibling control value
							targetKey: 'name', 		// The key of the sibling control which we want to write to
							applyWhen: 'always'  	// When the autocomplete value can be applied to sibling controls: 'empty', 'always' (only when empty or always)
						}
					}
				},
				{
					label: 'Company',
					value: 'company_id',
					required: true,				// Optional	config property to trigger validation on submission
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						name: 'company',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
					}
				},
				{
					label: 'Xero Contact',
					value: 'billing_id',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'contactID',
						icon: 'fa-piggy-bank',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contacts',
					}
				},
				{
					label: 'Subscription/Price Code',
					value: 'price_code',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						text: 'fullName',
						value: 'code',
						icon: 'fa-barcode',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/priceCodes',
					}
				},
				{
					label: 'Copy Tasks from a Template Venue',
					value: 'template_venue_id',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					noedit: true,
					autocomplete: {
						name: 'venue',
						text: 'name',
						value: 'id',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?is_template=1'
					}
				},
				{
					label: 'Copy Suppliers?',
					value: 'copy_suppliers',
					type: 'toggle',
					noedit: true,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Last Connected',
					value: 'app_last_connected',
					type: 'datetime',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Current App Version',
					value: 'app_version',
					readonly: true,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'App Updated',
					value: 'app_version_updated',
					type: 'datetime',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Start Date',
					value: 'start_date',
					convert: 'timestamp',
					readonly: true,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Use this as a template?',
					value: 'is_template',
					type: 'toggle',
					noadd: true,
					default: false,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Reading Devices',
					value: 'has_reading_devices',
					type: 'toggle',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'User Tracking',
					value: 'has_task_signoff',
					type:  'toggle',
					noadd: true,
					default: true,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Request Payment',
					value: 'request_payment',
					type:  'toggle',
					tooltip: 'If activated, a payment overdue notice will be added to the venue plan and will display in the app until this has setting is reverted.',
					noadd: true,
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Status',
					value: 'status',
					type: 'list',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					default: 'active',
					list: [
						{
							'name': 'New',
							'value': 'new',
						},		
						{
							'name': 'Trial',
							'value': 'trial',
						},
						{
							'name': 'Active',
							'value': 'active',
						},
						{
							'name': 'Suspended',
							'value': 'suspended',
						},
						{
							'name': 'Training',
							'value': 'training',
						},
						{
							'name': 'Blocked',
							'value': 'blocked',
						},
						{
							'name': 'Archived',
							'value': 'archived',
						}
					]
				},
				{
					label: 'Country',
					value: 'country',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						text: 'country_name',
						value: 'country_code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/countries'
					}
				},
				{
					label: 'Timezone',
					value: 'timezone',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						text: 'zone_name',
						value: 'zone_name',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/time_zones'
					}
				},
				{
					label: 'Parent Venue',
					value: 'parent_venue_id',
					type: 'autocomplete',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					noadd: true,
					autocomplete: {
						id: 'company_id',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}/venues'
					}
				},
				{
					label: 'Journal',
					value: 'journal',
					name: 'journal',
					noadd: true,
					cols: 12,
					type: 'table',
					headers: [
						{
							text: 'Action', 
							value: 'action' 
						},
						{
							text: 'Author', 
							value: 'author' 
						},
						{
							text: 'Date', 
							value: 'created_at',
							convert: 'datetime' 
						}
					]
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
				}
			]
		}
	},
	venue_link: {
		title: 'Venue Link',
		icon: 'fa-key',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		list: {
			items: [
				{ 
					header: 'Venue Link' 
				},
				{
					icon: 'fa-qrcode',
					title: 'Get a QR Code',
					subtitle:  'Opens a pop-up dialog featuring a QR code linking a device to this venues plan',
					path: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						post: process.env.VUE_APP_API_BASE_URL + '/auth/v1/fernet/qr?label=venueKeyAdmin&ttl=0&save=1&url=' + process.env.VUE_APP_QRCODE_BASE_URL,
						payload: '{ "companyId": <company_id>, "venueId": <id> }'
					}
				},
				{
					icon: 'fa-link',
					title: 'Get a Link',
					subtitle:  'Opens a pop-up dialog featuring a clickable link that will open the venues plan on a device',
					path: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						post: process.env.VUE_APP_API_BASE_URL + '/auth/v1/fernet/link?label=venueKeyAdmin&ttl=0&clickable=1&save=1&url=' + process.env.VUE_APP_LINK_BASE_URL,
						payload: '{ "companyId": <company_id>, "venueId": <id> }'
					}
				},
				{
					icon: 'fa-envelope-open',
					title: 'Email a Link',
					subtitle: 'Provide an email address and an email will be sent with a link and QR code to open the plan on a device',
					path: '/email_a_link/{id}?add=true',
				},
				{
					icon: 'fa-sms',
					title: 'TXT a Link',
					subtitle: 'Provide an international mobile number and an email will be sent with a link and QR code to open the plan on a device',
					path: '/txt_a_link/{id}?add=true',
				}
			]
		}
	},
	email_a_link: {
		title: 'Email a Link',
		icon: 'fa-envelope-open',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_link/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/link',
			design: [
				{
					label: 'Email Address',
					value: 'email',
				},
				{
					label: 'Type of Email to Send',
					value: 'type',
					type: 'list',
					default: '',
					list: [
						{
							name: 'Standard Venue Key Link',
							value: ''
						},
						{
							name: 'Checking In with Venue Key Link',
							value: 'checkingin'
						},
						{
							name: 'Invite to Personal Chomp',
							value: 'invite'
						},
						{
							name: 'Welcome to Chomp with App Links and Dashboard Links',
							value: 'releaseapp'
						},
						{
							name: 'REQUEST PAYMENT',
							value: 'overdue'
						},
						{
							name: 'SUBSCRIPTION CANCELLED',
							value: 'cancelled'
						},
						{
							name: 'OVERDUE VENUE BLOCKED',
							value: 'blocked'
						}
					]
				}
			]
		}
	},
	txt_a_link: {
		title: 'TXT a Link',
		icon: 'fa-sms',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_link/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/link',
			design: [
				{
					label: 'Mobile Number',
					value: 'mobile',
				},
				{
					label: 'Expiration / Time to Live (TTL)',
					value: 'ttl',
					type: 'list',
					list: [
						{
							name: 'never',
							value: 0
						},
						{
							name: '1 Day',
							value: 1
						},
						{
							name: '1 Month',
							value: 30
						}
					]
				}
			]
		}
	},
	employees: {
		icon: "fa-id-badge",
		nav: true,
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employees',
			delete: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{id}',
			add: '/employee',
			title: "Employees",
			description: 'Employee search, use the search box below to search and filter for employees recorded in Chomp',
			prefill: false,
			headers: [
				{
					text: 'Email',
					value: 'email',
					route: '/employee/{id}'
				},
				{
					text: 'Company',
					value: 'company_id',
					route: '/company/{company_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1&inactive=1&active=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'suspended': 'fa-ban',
						'archived': 'fa-archive'
					}
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	//
	employee: {
		icon: 'fa-id-badge',
		subtitle: {
			value: 'Employees',
			link: '/employees'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{id}?with_venues=1&with_training=1',
			patch: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee',
			design: [
				{
					label: 'Name',
					value: 'profile',
					key: 'name',
					name: 'name',
					type: 'banner',
					noadd: true,
					cols: 6,
					icon: 'fa-user',
				},
				{
					label: 'Employee ID',
					value: 'id',
					type: 'banner',
					noadd: true,
					cols: 6,
					icon: 'fa-id-card',
					tooltip: 'This is the employee ID in the Chomp database',
				},
				{
					label: 'Employee Email',
					value: 'email',
				},
				{
					label: 'Company',
					value: 'company_id',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{company_id}',
						search: 'id',
						return: 'name'
					},
					cols: 5,
				},
				{
					label: 'Profile Email',
					value: 'profile',
					key: 'email',
					name: 'profile_email',
					type: 'banner',
					noadd: true,
					cols: 7,
				},
				{
					label: 'Venues',
					value: 'venues',
					type: 'clicklist',
					route: '/venue_employees/{id}',
					showIf: {
						'name': 'venues',
						'operator': 'notEmpty'
					},
				},
				{
					label: 'Status',
					value: 'status',
					type: 'list',
					default: 'active',
					list: [
						{
							'name': 'Active',
							'value': 'active',
						},		
						{
							'name': 'Suspended',
							'value': 'suspended',
						},
						{
							'name': 'Archived',
							'value': 'archived',
						}
					]
				},
				{
					label: 'Training Verification Dates',
					value: 'training',
					type: 'table',
					headers: [
						{
							text: 'Date of Declaration', 
							value: 'declaration_date',
							convert: 'date',
							route: '/training/{employee_id}'
						},
						{ 
							text: 'Verified By', 
							value: 'verifier_employee_id',
							lookup: {
								url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{verifier_employee_id}',
								search: 'id',
								return: 'email'
							}
						},
						{
							text: 'Date of Verification',
							value: 'verification_date',
							convert: 'date'
						}
					]
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime',
					cols: 6
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime',
					cols: 6
				}
			]
		}
	},
	training: {
		title: 'Employee Training Records Completed',
		icon: "fa-user-graduate",
		subtitle: {
			value: 'Employee',
			link: '/employee/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/employee/{id}/training?orderBy=training_module_id&direction=asc',
			title: "Employee Training Records",
			headers: [
				{
					text: 'Module',
					value: 'training_module_id'
				},
				{
					text: 'Completed',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	move_venue: {
		title: 'Move Venue',
		icon: "fa-dolly",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		form: {
			header: "Move Venue to a Different Company",
			description: 'Use this form to move venue to a different company because it has been sold or whatever',
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/move',
			design: [
				{
					label: 'New Company',
					value: 'company_id',
					required: true,
					type: 'autocomplete',
					autocomplete: {
						name: 'company',
						text: 'name',
						value: 'id',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
					}
				},
				{
					label: 'Hubspot Venue',
					value: 'hubspot_company_id',
					required: false,				// Optional	config property to trigger validation on submission
					cols: 12,								// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						name: 'hubspot_company_id',
						text: 'name',
						value: 'id',
						icon: 'fa-utensils',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/hubspot/venues',
						suggestions: {					// Use a value returned in the autocomplete to populate another control
							sourceKey: 'name', 		// The key of the autocomplete item which we want to emit to populate a sibling control value
							targetKey: 'name', 		// The key of the sibling control which we want to write to
							applyWhen: 'always'  	// When the autocomplete value can be applied to sibling controls: 'empty', 'always' (only when empty or always)
						}
					}
				},
				{
					label: 'Xero Contact',
					value: 'billing_id',
					required: false,				// Optional	config property to trigger validation on submission
					highlight: false,			// Optional config property to add a highlight around this control
					cols: 12,							// Optional config property for layout (based on 12 col)
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'contactID',
						icon: 'fa-piggy-bank',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/contacts',
					}
				},
				{
					label: 'Archive Diary Records BEFORE This Date',
					value: 'archive_from',
					type: 'date'
				}
			]
		}
	},
	venue_account: {
		title: 'Venue Account',
		icon: "fa-coins",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		form: {
			header: "Venue Account",
			description: 'See details of the account for this venue',
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/account/{id}',
			design: [
				{
					label: 'Payment Notice!',
					value: 'payment_notice',
					type: 'banner',
					highlight: true,
					icon: 'fa-exclamation-circle',
					showIf: {
						'name': 'payment_notice',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Outstanding Balance',
					value: 'outstanding_total',
					type: 'banner',
					highlight: true,
					convert: 'currency',
					showIf: {
						'name': 'outstanding_total',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Venue Status',
					value: 'status',
					type: 'banner',
					convert: 'capitals',
					icon: {
						'new': 'fa-exclamation',
						'training': 'fa-graduation-cap',
						'trial': 'fa-stopwatch',
						'active': 'fa-check',
						'suspended': 'fa-hand-paper',
						'blocked': 'fa-comment-dollar',
						'archived':' fa-archive',
					}
				},
				{
					label: 'Activity Rating',
					value: 'activity',
					type: 'banner',
					icon: {
						'Chompian': 'fa-trophy',
						'Rookie': 'fa-baby',
						'Falling Star': 'fa-meteor',
						'Sinking Ship': 'fa-ship',
						'Ship Wreck': 'fa-bomb'
					}
				},
				{
					label: 'Last Diary Entry Performed On',
					value: 'last_diary_entry',
					key: 'performed_on',
					name: 'last_diary',
					type: 'banner',
					convert: 'datetime',
					showIf: {
						'name': 'last_diary_entry',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Billing Contact',
					value: 'billing_contact',
					type: 'banner'
				},
				{
					label: 'Subscription',
					value: 'subscription',
					type: 'autocomplete',
					autocomplete: {
						text: 'fullName',
						value: 'code',
						clearable: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/priceCodes'
					}
				},
				{
					label: 'Legal Entity',
					value: 'legal_entity',
					type: 'banner'
				},
				{
					label: 'Trading Name',
					value: 'trading_name',
					type: 'banner'
				},
				{
					label: 'App Release Date',
					value: 'release_date',
					type: 'banner',
					convert: 'date',
					showIf: {
						'name': 'release_date',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Outstanding Invoices',
					value: 'outstanding_invoices',
					type: 'table',
					headers: [
						{
							text: 'Reference', 
							value: 'reference' 
						},
						{ 
							text: 'Payment Method', 
							value: 'paymentType' 
						},
						{
							text: 'Invoice Due Date',
							value: 'dueDate',
							convert: 'date'
						},
						{ 
							text: 'Currency', 
							value: 'currencyCode' 
						},
						{ 
							text: 'Sub Total', 
							value: 'subTotal',
							convert: 'currency'
						},
						{
							text: 'See Invoice',
							value: 'invoiceID',
							icon: 'fa-external-link-alt',
							route: 'https://go.xero.com/app/!jSnWd/invoicing/view/{invoiceID}'
						}
					]
				},
				{
					label: 'Payment History',
					value: 'payment_history',
					type: 'table',
					headers: [
						{
							text: 'Reference', 
							value: 'reference' 
						},
						{ 
							text: 'Payment Method', 
							value: 'paymentType' 
						},
						{
							text: 'Invoice Due Date',
							value: 'dueDate',
							convert: 'date'
						},
						{
							text: 'Invoice Paid Date',
							value: 'fullyPaidOnDate',
							convert: 'date'
						},
						{ 
							text: 'Currency', 
							value: 'currencyCode' 
						},
						{ 
							text: 'Sub Total', 
							value: 'subTotal',
							convert: 'currency'
						},
						{
							text: 'See Invoice',
							value: 'invoiceID',
							icon: 'fa-external-link-alt',
							route: 'https://go.xero.com/app/!jSnWd/invoicing/view/{invoiceID}'
						}
					]
				},
				{
					label: 'Paid Invoice Total',
					value: 'paid_total',
					type: 'banner',
					convert: 'currency'
				},
				{
					label: 'Life Time Value',
					value: 'lifetimeValue',
					type: 'banner',
					convert: 'currency'
				},
				{
					label: 'Upcoming Invoices',
					value: 'upcoming_invoices',
					type: 'table',
					headers: [
						{
							text: 'Reference', 
							value: 'reference' 
						},
						{ 
							text: 'Sub Total', 
							value: 'subTotal' 
						},
						{ 
							text: 'Payment Method', 
							value: 'paymentType' 
						},
						{ 
							text: 'Agent', 
							value: 'authors' 
						},
						{ 
							text: 'Next Scheduled', 
							value: 'nextScheduledDate',
							convert: 'date'
						},
						{
							text: 'See Repeating Invoice',
							value: 'repeatingInvoiceID',
							icon: 'fa-external-link-alt',
							route: 'https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID={repeatingInvoiceID}&type=AR'
						}
					]
				},
				{
					label: 'Managers',
					value: 'managers',
					type: 'table',
					headers: [
						{
							text: 'Name', 
							value: 'first_name' 
						},
						{ 
							text: '', 
							value: 'last_name' 
						},
						{ 
							text: 'Email', 
							value: 'email' 
						}
					]
				},
				{
					label: 'Back Office',
					value: 'back_office',
					type: 'table',
					headers: [
						{
							text: 'Name', 
							value: 'first_name' 
						},
						{ 
							text: '', 
							value: 'last_name' 
						},
						{ 
							text: 'Email', 
							value: 'email' 
						}
					]
				}
			]
		}
	},
	venue_upcoming_invoices: {
		icon: "fa-file-invoice-dollar",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/invoices/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/invoices/{id}?orderBy=nextScheduledDate&direction=asc',
			headers: [
				{
					text: 'Company',
					value: 'name',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{contactID}'
				},	
				{
					text: 'Reference',
					value: 'reference',
				},				
				{
					text: 'Sub Total',
					value: 'subTotal'
				},
				{
					text: 'Currency',
					value: 'currencyCode'
				},
				{
					text: 'Status',
					value: 'status'
				},
				{
					text: 'Payment Method',
					value: 'paymentType'
				},
				{
					text: 'Due Date',
					value: 'nextScheduledDate',
					convert: 'date'
				}
			]
		}
	},
	venue_outstanding_invoices: {
		icon: "fa-comment-dollar",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/invoices/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/invoices/{id}?status=outstanding&orderBy=dueDate&direction=desc',
			headers: [
				{
					text: 'Company',
					value: 'name',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{contactID}'
				},	
				{
					text: 'Reference',
					value: 'reference',
				},				
				{
					text: 'Sub Total',
					value: 'subTotal'
				},
				{
					text: 'Currency',
					value: 'currencyCode'
				},
				{
					text: 'Payment Method',
					value: 'paymentType'
				},
				{
					text: 'Status',
					value: 'status'
				},
				{
					text: 'Due Date',
					value: 'dueDate',
					convert: 'date'
				},
				{
					text: 'Plan Activity',
					value: 'activity',
					icon: {
						0: 'fa-baby',
						1: 'fa-chompion',
						2: 'fa-meteor',
						3: 'fa-ship',
						4: 'fa-bomb',
						5: 'fa-question'
					}
				},
				{
					text: 'Sent',
					value: 'sentToContact',
					icon: {
						'true': 'fa-check'
					}
				},
				{
					text: 'View Invoice',
					value: 'invoiceID',
					icon: 'fa-external-link-alt',
					route: 'https://go.xero.com/app/!jSnWd/invoicing/view/{invoiceID}'
				}
			]
		}
	},
	venue_paid_invoices: {
		icon: "fa-receipt",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/invoices/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/billing/invoices/{id}?status=paid&orderBy=dueDate&direction=desc',
			headers: [
				{
					text: 'Company',
					value: 'name',
					route: 'https://go.xero.com/app/!jSnWd/contacts/contact/{contactID}'
				},	
				{
					text: 'Reference',
					value: 'reference',
				},				
				{
					text: 'Sub Total',
					value: 'subTotal'
				},
				{
					text: 'Currency',
					value: 'currencyCode'
				},
				{
					text: 'Payment Method',
					value: 'paymentType'
				},
				{
					text: 'Status',
					value: 'status'
				},
				{
					text: 'Due Date',
					value: 'dueDate'
				},
				{
					text: 'View Invoice',
					value: 'invoiceID',
					icon: 'fa-external-link-alt',
					route: 'https://go.xero.com/app/!jSnWd/invoicing/view/{invoiceID}'
				}
			]
		}
	},
	venue_devices: {
		icon: "fa-tablet-alt",
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/devices/{id}?orderBy=activity&direction=desc',
			delete: process.env.VUE_APP_API_BASE_URL + '/venues/v1/device/{id}',
			headers: [
				{
					text: 'Device ID',
					value: 'id',				
					route: '/device/{id}'
				},
				{
					text: 'App Version',
					value: 'app_version',
				},				
				{
					text: 'App Version Updated',
					value: 'app_version_updated'
				},
				{
					text: 'Agent',
					value: 'agent'
				},
				{
					text: 'Browser',
					value: 'browser'
				},
				{
					text: 'Last Connected',
					value: 'activity',
					name: 'last_connected'
				}
			]
		}
	},
	devices: {
		icon: "fa-tablet-alt",
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/devices',
			delete: process.env.VUE_APP_API_BASE_URL + '/venues/v1/device/{id}',
			headers: [
				{
					text: 'Device ID',
					value: 'id',	
					route: '/device/{id}'
				},
				{
					text: 'App Version',
					value: 'app_version',
				},				
				{
					text: 'App Version Updated',
					value: 'app_version_updated'
				},				
				{
					text: 'Agent',
					value: 'agent'
				}
			]
		}
	},
	device: {
		icon: "fa-tablet-alt",
		subtitle: {
			value: 'Devices',
			link: '/devices'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/device/{id}',
			noactions: true,
			design: [
				{
					label: 'Device',
					value: 'id',
					noadd: true,
					readonly: true
				},
				{
					label: 'Current App Version',
					value: 'app_version',
					readonly: true
				},				
				{
					label: 'App Version Last Updated',
					value: 'app_version_updated',
					type: 'datetime'
				},
				{
					label: 'Browser Information',
					value: 'browser',
					noadd: true,
					readonly: true
				},
				{
					label: 'Agent',
					value: 'agent',
					noadd: true,
					readonly: true
				},
				{
					label: 'Venues Loaded On This Device',
					value: 'venues',
					type: 'table',
					headers: [
						{
							text: 'Venue', 
							value: 'name' 
						},
						{ 
							text: 'Last Connected', 
							value: 'last_connected' 
						}
					]
				},
				{
					label: 'First Used',
					value: 'created_at',
					type: 'datetime'
				}
			]
		}
	},
	profiles: {
		icon: "fa-id-card",
		nav: true,
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profiles',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
			add: '/profile',
			title: 'Profiles',
			description: 'Profile search, use the search box below to search and filter for profiles recorded in Chomp',
			headers: [
				{
					text: 'Profile Email',
					value: 'email',
					route: '/profile/{id}'
				},
				{
					text: 'First Name',
					value: 'first_name'
				},
				{
					text: 'Last Name',
					value: 'last_name',
				},
				{
					text: 'User',
					value: 'user',
					key: 'id',
					icon: 'fa-user',
					route: '/user/{id}'
				},
				{
					text: 'Mobile',
					value: 'mobile',
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	profile: {
		icon: 'fa-id-card',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile',
			design: [
				{
					label: 'Profile UUID',
					value: 'id',
					noadd: true,
					readonly: true
				},
				{
					label: 'First Name',
					value: 'first_name',
				},
				{
					label: 'Last Name',
					value: 'last_name',
				},
				{
					label: 'Email',
					value: 'email'
				},
				{
					label: 'Mobile',
					value: 'mobile'
				},	
				{
					label: 'Date of Birth',
					value: 'dob',
					type:  'date',
					date: {
						direction: 'past'
					}
				},
				{
					label: 'Country',
					value: 'country',
					type: 'autocomplete',
					autocomplete: {
						name: 'code',
						text: 'country',
						value: 'code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/countries',
					}
				},
				{
					label: 'Language',
					value: 'language',
					type: 'autocomplete',
					autocomplete: {
						name: 'language',
						text: 'language',
						value: 'code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/languages',
					}
				},
				{
					label: 'Send Password Reset',
					type: 'button',
					value: 'email',
					noadd: true,
					put: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/reset',
					payload: '{ "email": "<email>" }',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	invite: {
		icon: 'fa-user-plus',
		form: {
			post: process.env.VUE_APP_API_BASE_URL + '/app/v3/invite/create',
			design: [
				{
					label: 'Invitation Token',
					value: 'id',
					name: 'token',
					type: 'banner'
				},
				{
					label: 'Email',
					value: 'email',
					type: 'banner'
				},
				{
					label: 'First Name',
					value: 'first_name',
				},
				{
					label: 'Last Name',
					value: 'last_name',
				},
				{
					label: 'Mobile',
					value: 'mobile'
				},	
				{
					label: 'Date of Birth',
					value: 'dob',
					type:  'date',
					date: {
						direction: 'past'
					}
				},
				{
					label: 'Country',
					value: 'country',
					type: 'autocomplete',
					autocomplete: {
						name: 'code',
						text: 'country',
						value: 'code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/countries',
					}
				},
				{
					label: 'Language',
					value: 'language',
					type: 'autocomplete',
					autocomplete: {
						name: 'language',
						text: 'language',
						value: 'code',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/languages',
					}
				}
			]
		}
	},
	invite_to_venue: {
		icon: 'fa-user-plus',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		form: {
			post: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/invite',
			design: [
				{
					label: 'Venue',
					value: 'id',
					name: 'venueId',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						return: 'name'
					}
				},
				{
					label: 'Provide an email address to send an invitation',
					name: 'email',
					value: 'email'
				}
			]
		}
	},
	suppliers: {
		icon: "fa-truck",
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/suppliers',
			add: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/supplier/{id}',
			headers: [
				{
					text: 'Suppliers',
					value: 'name',
					id: 'id',
					route: '/supplier/{id}'
				},
				{
					text: 'Type',
					value: 'supplier_type',
					icon: {
						'goods': 'fa-shopping-basket',
						'services': 'fa-toolbox',
						'other': 'fa-warehouse'
					}
				},
				{
					text: 'Venue',
					value: 'venue_id',
					route: '/venue/{id}',
					id: 'venue_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
						search: 'id',
						return: 'name'
					}
				}
			]
		}
	},
	supplier: {
		icon: "fa-truck",
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/supplier/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/supplier',
			design: [
				{
					label: 'Venue',
					value: 'id',
					name: 'venue_id',
					type: 'banner',
					noedit: true,
				},
				{
					label:  'Assigning the supplier to a company will make that supplier visible to all venues for that company.',
					type: 'note'
				},
				{
					label: 'Company',
					value: 'company_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'company',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1'
					}
				},
				{
					label: 'Supplier Name',
					value: 'name'
				},
				{
					label: 'Contact',
					value: 'contact'
				},
				{
					label: 'Email',
					value: 'email'
				},
				{
					label: 'Phone',
					value: 'phone'
				},
				{
					label: 'Address',
					value: 'address'
				},
				{
					label: 'Lead Time',
					value: 'lead_time'
				},
				{
					label: 'Delivery Days',
					value: 'delivery_days'
				},
				{
					label: 'Goods Supplied',
					value: 'goods_supplied'
				},
				{
					label: 'Comments',
					value: 'comments',
				},
				{
					label: 'Supplier Type',
					value: 'supplier_type',
					type: 'list',
					list: [
						{
							'name': 'services',
							'value': 'services'
						},
						{
							'name': 'goods',
							'value': 'goods'
						},
						{
							'name': 'other',
							'value': 'other'
						}
					]
				},
				{
					label: 'Certificate',
					value: 'certificate',
					type:  'link',
					icon:  'fa-certificate',
					route: '/certificate/{id}',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		},
	},
	certificate: {
		icon: "fa-certificate",
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/supplier-certificate/{id}',
			design: [
				{
					label: 'File Name',
					value: 'file_name'
				},
				{
					label: 'Certificate Number',
					value: 'certificate_number'
				},
				{
					label: 'Registration Number',
					value: 'registration_number'
				},
				{
					label: 'Valid Until',
					value: 'valid_to'
				},
				{
					label: 'Download Certificate',
					value: 'id',
					type:  'download',
					filename: 'filename',
					icon:  'fa-file-download',
					route: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/certificate/{id}',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		},
	},
	document: {
		icon: "fa-certificate",
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue-document/{id}',
			design: [
				{
					label: 'Document Name',
					value: 'name'
				},
				{
					label: 'File Name',
					value: 'file_name'
				},
				{
					label: 'Document Type',
					value: 'document_type'
				},
				{
					label: 'Content Type',
					value: 'content_type'
				},
				{
					label: 'Download File',
					value: 'id',
					type:  'download',
					filename: 'filename',
					icon:  'fa-file-download',
					route: process.env.VUE_APP_API_BASE_URL + '/venues/v1/document/{id}',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		},
	},
	venue_suppliers: {
		icon: 'fa-truck',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/suppliers/{id}',
			delete: process.env.VUE_APP_API_BASE_URL + '/suppliers/v1/supplier/{id}',
			add: '/supplier/{id}',
			headers: [
				{
					text: 'Supplier',
					value: 'name',
					id: 'id',
					route: '/supplier/{id}'
				},
				{
					text: 'Type',
					value: 'supplier_type',
					icon: {
						'goods': 'fa-shopping-basket',
						'services': 'fa-toolbox',
						'other': 'fa-warehouse'
					}
				}
			]
		}
	},
	venue_documents: {
		icon: 'fa-file-pdf',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/documents',
			headers: [
				{
					text: 'Document',
					value: 'name',
					id: 'id',
					route: '/document/{id}'
				},
				{
					text:  'Type',
					value: 'document_type',
				},
				{
					text:  'Content',
					value: 'content_type',
				}
			]
		}
	},
	venue_employees: {
		icon: 'fa-id-badge',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}/employees?with_profile=1',
			headers: [
				{
					text: 'Employee Email',
					value: 'email',
					route: '/employee/{id}',
				},
				{
					text: 'Name',
					value: 'profile',
					key: 'name'
				},
				{
					text: 'Profile Email',
					value: 'profile',
					key: 'email',
					name: 'profile_email',
					route: '/profile/{id}',
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'suspended': 'fa-ban',
						'archived': 'fa-archive'
					}
				}
			]
		}
	},
	closed_days: {
		icon: 'fa-door-closed',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/closed/{id}?orderBy=start&direction=desc',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/closed/{id}',
			add: '/closed_day/{id}',
			headers: [
				{
					text: 'Start Date',
					value: 'start',
					convert: 'date'
				},
				{
					text: 'End Date',
					value: 'end',
					convert: 'date'
				},
				{
					text: 'RRule',
					value: 'rrule',
				},
				{
					text: 'Day Count',
					value: 'count',
				}
			]
		}
	},
	closed_day: {
		icon: 'fa-door-closed',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/closed/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/venues/v1/closed/{id}',
			design: [
				{
					label: 'Start Date',
					value: 'start',
					type: 'date'
				},
				{
					label: 'End Date',
					value: 'end',
					type: 'date'
				},
				{
					label: 'RRule',
					value: 'rrule',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	venue_segments: {
		icon: 'fa-columns',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/segments/{id}',
			delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/segment/{id}',
			add: '/segment?venue_id={id}',
			headers: [
				{
					text: 'Segment Name',
					value: 'name',
					id: 'id',
					route: '/segment/{id}',
				}
			]
		}
	},
	segment: {
		icon: 'fa-columns',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_segments/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/segment/{id}',
			add:  process.env.VUE_APP_API_BASE_URL + '/tasks/v1/segment',
			design: [
				{
					label: 'Segment Name',
					value: 'name'
				},
				{
					label: 'Tasks',
					value: 'tasks',
					id: 'venue_id',
					type: 'checklist',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/tasks/{id}',
						return: {
							'name': 'name',
							'value': 'id'
						}
					}
				}
			]
		}
	},
	tasks: {
		icon: 'fa-list',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/tasks/{id}?withTemplate=1',
			add: '/task?venue_id={id}',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/task/{id}',
				},
				{
					text: 'Type',
					value: 'type',
					icon: {
						'schedule': 'fa-calendar-alt',
						'frequent': 'fa-clock',
						'event': 'fa-bell',
						'inductance': 'fa-graduation-cap'
					}
				},
				{
					text: 'Template',
					value: 'template_id',
					route: '/template/{template_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Category',
					value: 'category_id',
					route: '/category/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Latest Entries',
					value: 'venue_id',
					route: '/diary_entries_by_task/{venue_id}?task_id={id}',
					icon: 'fa-book'
				},
				{
					text: 'Device',
					value: 'device',
					route: '/reading_device/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/device/{id}',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Archive',
					value: 'id',
					icon: 'fa-archive',
					update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}/archive'
				}
			]
		}
	},
	task: {
		icon: 'fa-list',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}/?withDeleted=1&withJournal=1',
			add:  process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/',
			patch:  process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}',
			design: [
				{
					label: 'Venue',
					value: 'venue_id',
					name: 'venue',
					type: 'banner',
					noedit: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						search: 'id',
						return: 'name'
					}
				},
				{
					label: 'Choose a Template',
					value: 'template_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates'
					}
				},
				{
					label: 'Task Name',
					value: 'name'
				},
				{
					label: 'Method',
					value: 'method'
				},
				{
					label: 'User Defined Record Name', 
					value: 'user_defined_record_name',
					tooltip: 'When this is set, the data recorded by the used to this model will be used as the name of the record in the diary',
					type: 'select',
					noadd: true,
					id: 'template_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/components/{template_id}/models'
					},
					showIf: {
						'name': 'template_id',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'This task requires proving when proven count is less than 3.',
					value: 'template',
					name: 'has_proving',
					key: 'has_proving',
					type: 'note',
					noadd: true,
					showIf: {
						'name': 'has_proving',
						'operator': 'equalTo',
						'value': 1
					}
				},
				{
					label: 'Current Proven Count',
					value: 'proven_count',
					type: 'list',
					list: [
						{
							'name': '0',
							'value': 0
						},
						{
							'name': '1',
							'value': 1
						},
						{
							'name': '2',
							'value': 2
						},
						{
							'name': '3',
							'value': 3
						}
					],
					noadd: true,
					showIf: {
						'name': 'has_proving',
						'operator': 'equalTo',
						'value': 1
					}
				},
				{
					label: 'Reset the Proven Count On This Date',
					value: 'needs_proven_from',
					type: 'date',
					noadd: true,
					showIf: {
						'name': 'has_proving',
						'operator': 'equalTo',
						'value': 1
					}
				},
				{
					label: 'Schedule',
					value: 'schedule',
					type: 'link',
					icon:  'fa-calendar',
					route: '/schedule/{id}',
					id: 'schedule',
					noadd: true,
					showIf: {
						'name': 'schedule',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Source',
					value: 'source',
					type: 'list',
					default: 'manual',
					list: [
						{
							'name': 'Manual',
							'value': 'manual',
						},
						{
							'name': 'Sensor',
							'value': 'sensor',
						}
					]
				},
				{
					label: 'Device',
					value: 'device',
					type: 'autocomplete',
					autocomplete: {
						name: 'devices',
						text: 'name',
						value: 'id',
						id: 'venue_id',
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/devices/2?venueId={id}'
					},
					showIf: {
						'name': 'source',
						'operator': 'equalTo',
						'value': 'sensor'
					}
				},
				{
					label: 'Journal',
					value: 'journal',
					noadd: true,
					type: 'table',
					headers: [
						{
							text: 'Action', 
							value: 'action' 
						},
						{
							text: 'Author', 
							value: 'author' 
						},
						{
							text: 'Date', 
							value: 'created_at',
							convert: 'datetime' 
						}
					]
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				} 
			]
		},
	},
	schedule: {
		icon:  'fa-calendar',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/schedule/{id}',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/schedule/{id}',
			design: [
				{
					label: 'Task',
					value: 'task_id',
					readonly: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}',
						search: 'id',
						return: 'name'
					}
				},
				{
					label: 'Frequency',
					value: 'frequency_key',
					type: 'autocomplete',
					autocomplete: {
						name: 'key',
						text: 'name',
						value: 'key',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/frequencies'
					}
				},
				{
					label: 'Anniversary',
					value: 'anniversary_key',
					type: 'autocomplete',
					autocomplete: {
						name: 'key',
						text: 'label',
						value: 'key',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/anniversaries'
					}
				},
				{
					label: 'Start Date',
					value: 'start_date',
					type: 'date'
				},
				{
					label: 'Start Time',
					value: 'start_time',
					type: 'time'
				},
				{
					label: 'Schedule',
					value: 'rruleset',
					type: 'textarea',
					size: 2,
					readonly: true
				},
				{
					label: 'Past Schedules',
					value: 'components',
					type: 'table',
					noadd: true,
					url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}/archived-schedules',
					headers: [
						{
							text: 'ID', 
							value: 'id' 
						},
						{
							text: 'Rule Set', 
							value: 'rruleset' 
						},
						{
							text: 'Archived', 
							value: 'archived' 
						}
					]
				},
				{
					label: 'Schedule Cheat Sheet',
					value: 'cheatsheet',
					type: 'list',
					readonly: true,
					list: [
						{
							value: 'IMPORTANT!',
							name: 'IMPORTANT!  There must be a carriage return/line feed between DTSTART and anything after RRULE'
						},
						{
							value: 'DTSTART',
							name: 'DTSTART: Defines the date and time after which this schedule begins'
						},
						{
							value: 'FREQ',
							name: 'FREQ: Defines the frequency of the event. Possible values are SECONDLY, MINUTELY, HOURLY, DAILY, WEEKLY, MONTHLY, YEARLY'
						},
						{
							value: 'INTERVAL',
							name: 'INTERVAL: Defines the interval between each FREQ. For example, FREQ=DAILY;INTERVAL=2 means the event occurs every other day'
						},
						{
							value: 'UNTIL',
							name: 'UNTIL: Defines the date-time after which the recurrence rule stops. For example, FREQ=DAILY;UNTIL=20231231T000000Z means the event occurs daily until the end of 2023'
						},
						{
							value: 'COUNT',
							name: 'COUNT: Defines the number of occurrences at which to stop the recurrence. For example, FREQ=DAILY;COUNT=10 means the event occurs daily for 10 days'
						},
						{
							value: 'BYxxx',
							name: 'BYxxx: Defines specific instances for the recurrence rule. BYxxx could be one of the following: BYSECOND, BYMINUTE, BYHOUR, BYDAY, BYMONTHDAY, BYYEARDAY, BYWEEKNO, BYMONTH, BYSETPOS'
						}
					]
				}
			]
		}
	},
	archived_tasks: {
		icon: 'fa-list',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue_plan/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/tasks/{id}?withTemplate=1&onlyDeleted=1',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/task/{id}'
				},
				{
					text: 'Type',
					value: 'type',
					icon: {
						'schedule': 'fa-calendar-alt',
						'frequent': 'fa-clock',
						'event': 'fa-bell',
						'inductance': 'fa-graduation-cap'
					}
				},
				{
					text: 'Template',
					value: 'template_id',
					route: '/template/{template_id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Category',
					value: 'category_id',
					route: '/category/{id}',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Latest Entries',
					value: 'venue_id',
					route: '/diary_entries_by_task/{venue_id}?task_id={id}',
					icon: 'fa-book'
				},
				{
					text: 'Device',
					value: 'device',
					route: '/reading_device/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/device/{id}',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Unarchive',
					value: 'id',
					icon: 'fa-trash-restore',
					update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}/unarchive'
				}
			]
		}
	},
	diaries: {
		icon: 'fa-books',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/home'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/diaries/v1/diaries?venue_id={id}',
			headers: [
				{
					text: 'Venue',
					value: 'venue_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'View Diary',
					value: 'id',
					route: '/diary/{id}',
					icon: 'fa-file'
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime',
				}
			]
		}
	},
	diary: {
		icon: 'fa-book',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/diaries/v1/entries/{id}/latest?withTemplate=1&skipCache=1&orderBy=performed_on&direction=desc',
			headers: [
				{
					text: 'Diary Entry',
					value: 'id',
					route: '/diary_entry/{id}'
				},
				{
					text: 'Task Name',
					value: 'task_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/tasks/{id}',
						search: 'id',
						return: 'name'
					},
					route: '/task/{task_id}'
				},
				{
					text: 'Due Date',
					value: 'due',
					convert: 'date'
				},
				{
					text: 'Performed On',
					value: 'performed_on',
					convert: 'datetime'
				},
				{
					text: 'User',
					value: 'user_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/legacy/users?venueId={id}',
						search: 'id',
						return: 'email'
					},
					route: '/legacy_user/{user_id}'
				},
				{
					text: 'More',
					value: 'id',
					route: '/diary_entries_by_task/{venue_id}?task_id={task_id}',
					icon: 'fa-book'
				},
				{
					text: 'Edit',
					value: 'id',
					route: '/diary_entry/{id}',
					icon: 'fa-pen'
				}
			]
		}
	},
	diary_entries_by_task: {
		icon: 'fa-book',
		subtitle: {
			value: 'Venue Tasks',
			link: '/tasks/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/diaries/v1/entries/{id}?taskIds[]={task_id}&orderBy=performed_on&direction=desc',
			headers: [
				{
					text: 'Entry ID',
					value: 'id',
					route: '/diary_entry/{id}'
				},
				{
					text: 'Due',
					value: 'due',
					convert: 'datetime'
				},
				{
					text: 'Performed On',
					value: 'performed_on',
					convert: 'datetime'
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				},
				{
					text: 'User',
					value: 'user_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/legacy/users?venueId={venue_id}',
						search: 'id',
						return: 'email'
					},
					route: '/legacy_user/{user_id}'
				},
				{
					text: 'Proven',
					value: 'proven',
					icon: {
						0: '',
						1: 'fa-check'
					}
				},
				{
					text: 'Has Files',
					value: 'has_files',
					icon: 'fa-file'
				},
				{
					text: 'Expired',
					value: 'expired',
					icon: {
						0: '',
						1: 'fa-hourglass-end'
					}
				}
			]
		}
	},
	diary_entry: {
		icon: 'fa-clipboard-check',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/diaries/v1/entry/{id}',
			design: [
				{
					label: 'Venue',
					value: 'venue_id',
					name: 'venue',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
						return: 'name'
					}
				},
				{
					label: 'Task',
					value: 'task_id',
					name: 'task',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/task/{id}',
						return: 'name'
					}
				},
				{
					label: 'Due Date',
					value: 'due',
					type: 'date'
				},
				{
					label: 'Performed On',
					value: 'performed_on',
					type: 'date'
				},
				{
					label: 'Information',
					value: 'information',
					type: 'json'
				},
				{
					label: 'Files',
					value: 'files',
					type: 'clicklist',
					icon: 'fa-file-download'
				},				
				{
					label: 'Expired',
					value: 'expired',
					type:  'toggle'
				},
				{
					label: 'Entry',
					value: 'entry',					
					showIf: {
						'name': 'entry',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Note',
					value: 'note',
					showIf: {
						'name': 'note',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'User',
					value: 'user_id',
					readonly: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}?legacy=1',
						return: 'email'
					}
				},
				{
					label: 'UUID',
					value: 'uuid',					
					type: 'banner'
				},
				{
					label: 'Diary',
					value: 'diary_id',
					readonly: true,				
					showIf: {
						'name': 'entry',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				}
			]
		}
	},
	categories: {
		nav: true,
		icon: 'fa-layer-group',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories',
			add: '/category',
			delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category/{id}',
			title: 'Categories',
			description: 'Search for Chomp categories.',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/category/{id}'
				},
				{
					text: 'Templates',
					value: 'id',
					route: '/category_templates/{id}',
					icon: 'fa-columns'
				},
				{
					text: 'Rank',
					value: 'rank'
				}
			]
		}
	},
	category: {
		icon: 'fa-layer-group',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category/{id}?withLiterals=1&withConfiguration=1',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category',
			design: [
				{
					label: 'Name',
					value: 'name'
				},
				{
					label: 'Description',
					value: 'description'
				},
				{
					label: 'Ranking (1 is highest rank)',
					value: 'rank',
					type: 'select',
					range: {
						"min": 1,
						"max": 100
					},
				},
				{
					label: 'Clone Configuration',
					value: 'id',
					type: 'link',
					route: '/clone_category_configuration/{id}',
					icon: 'fa-clone'
				},
				{
					label: 'Configuration',
					value: 'literals',
					noadd: true,
					type: 'table',
					add: '/category_configuration?category_id={id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration/{id}',
					headers: [
						{
							text: 'Name', 
							value: 'name' 
						},
						{ 
							text: 'Value', 
							value: 'value'
						},
						{
							text: 'Type',
							value: 'type',
							icon: {
								'string': 'fa-font',
								'number': 'fa-hashtag',
								'boolean': 'fa-toggle-on',
								'list': 'fa-list',
								'object': 'fa-tag',
								'objects': 'fa-tags',
							},
						},
						{ 
							text: 'Configurable', 
							value: 'edittable',			
							icon: {
								1: 'fa-check-circle'
							}
						},
						{ 
							text: 'Edit', 
							value: 'id',					
							route: '/category_configuration/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Configuration JSON',
					value: 'configuration',
					type: 'json'
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	clone_category_configuration: {
		icon: 'fa-columns',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category/{id}',
			post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration/copy/{id}',
			design: [
				{
					label: 'Source Category',
					value: 'name',
					name: 'category',
					type: 'banner'
				},
				{
					label: 'Configuration to Copy',
					value: 'output',
					type: 'json',
					id: 'id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration/build?category_id={id}'
					}
				},
				{
					label: 'Select a Destination Category',
					value: 'destination_category_id',
					type: 'autocomplete',
					id: 'id',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories'
					}
				},
				{
					label: 'Replace existing configuration?',
					value: 'replace',
					type: 'toggle',
					default: true
				}
			]
		}
	},
	category_configuration: {
		icon: 'fa-columns',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration',
			design: [
				{
					label: 'Category',
					value: 'category_id',
					name: 'category',
					type: 'banner',
					id: 'category_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category/{id}',
						return: 'name'
					}
				},
				{
					label: 'Parent Configuration',
					value: 'parent_configuration_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						id: 'category_id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configurations?category_id={id}&can_parent=1'
					}
				},
				{
					label: 'Type',
					value: 'type',
					type: 'select',
					list: [
						{
							name: 'String',
							value: 'string'
						},
						{
							name: 'Number',
							value: 'number'
						},
						{
							name: 'True/False',
							value: 'boolean'
						},
						{
							name: 'Object',
							value: 'object'
						},
						{
							name: 'List of Values',
							value: 'list'
						},
						{
							name: 'List of Objects',
							value: 'objects'
						}
					]
				},
				{
					label: 'Name',
					value: 'name',
				},
				{
					label: 'Value',
					value: 'value'
				},
				{
					label: 'Configurable',
					value: 'edittable',
					type: 'toggle',
					default: false
				},
				{
					label: 'Configuration Output',
					value: 'output',
					type: 'json',
					id: 'category_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category_configuration/build?category_id={id}'
					}
				}
			]
		}
	},
	category_templates: {
		icon: 'fa-columns',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/category/{id}',
			value: 'name',
			link: '/categories'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates?forCategory={id}',
			add: '/template',
			headers: [
				{
					text: 'Name',
					value: 'name',
					id: 'id',
					route: '/template/{id}'
				},
				{
					text: 'Type',
					value: 'type',
					icon: {
						'schedule': 'fa-calendar-alt',
						'inductance': 'fa-graduation-cap',
						'event': 'fa-bell',
						'frequent': 'fa-clock'					
					}
				},
				{
					text: 'Configuration',
					value: 'id',
					route: '/template_configuration/{id}',
					icon: 'fa-cog'
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'inactive': 'fa-times',
						'archived': 'fa-archive'
					}
				}
			]
		}
	},
	template: {
		icon: 'fa-columns',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}?withConfiguration=1&withLiterals=1',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template',
			design: [
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				},
				{
					label: 'Template Name',
					value: 'name'
				},
				{
					label: 'Title',
					value: 'title'
				},
				{
					label: 'Information',
					value: 'info'
				},
				{
					label: 'Category',
					value: 'category_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/categories'
					}				
				},
				{
					label: 'Task Type',
					value: 'type',
					type: 'list',
					list: [
						{
							'name': 'Schedule',
							'value': 'schedule'
						},
						{
							'name': 'Frequent',
							'value': 'frequent'
						},
						{
							'name': 'Event',
							'value': 'event'
						}
					]
				},
				{
					label: 'Anniversary',
					value: 'anniversary_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'label',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/anniversaries'
					}
				},
				{
					label: 'Frequency',
					value: 'frequency_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/frequencies'
					}
				},
				{
					label: 'Menu Key',
					tooltip: 'A unique key that will be used to place the template within the Dashboard main menu',
					value: 'key'
				},				
				{
					label: 'Task View Format',
					tooltip: 'Determines the type of task setup form the user will be given when adding a task of this type to their plan',
					value: 'task_view',
					type: 'list',
					list: [
						{
							'name': 'Day/Week Scheduling',
							'value': 'day-and-week'
						},
						{
							'name': 'Refrigeration',
							'value': 'fridges'
						},
						{
							'name': 'Shared Scheduling',
							'value': 'shared-schedule'
						},
						{
							'name': 'Event',
							'value': 'event'
						},
						{
							'name': 'Month/Year Scheduling',
							'value': 'month-and-year'
						}
					]
				},
				{
					label: 'Audit Calender View Format',
					tooltip: 'Determines the type of view under which diary entries from tasks of this template will show in the Audit Report',
					value: 'audit_view',
					type: 'list',
					list: [
						{
							'name': 'List',
							'value': 'listly'
						},
						{
							'name': 'Daily',
							'value': 'daily'
						},
						{
							'name': 'Weekly',
							'value': 'weekly'
						},
						{
							'name': 'Monthly',
							'value': 'monthly'
						},
						{
							'name': 'Yearly',
							'value': 'yearly'
						}
					]
				},
				{
					label: 'Audit Calendar Entry Format',
					tooltip: 'Determines the for diary entries from tasks of this template within the Audit Report calendar view',
					value: 'audit_event_view',
					type: 'list',
					list: [
						{
							'name': 'Checked',
							'value': 'checked'
						},
						{
							'name': 'Temperature',
							'value': 'temperature'
						},
						{
							'name': 'Received Goods',
							'value': 'received'
						}
					]			
				},
				{
					label: 'Audit Entry Detail',
					tooltip: 'A unique key that identifies which detail view template will be used to display diary information within the Audit Report when clicking on an entry.',
					value: 'audit_detail_view'
				},
				{
					label: 'Audit List View',
					value: 'audit_list_view',
					type: 'toggle'
				},
				{
					label: 'Allow for setting method on task',
					value: 'has_method',
					type: 'toggle'
				},
				{
					label: 'Allow task to use automated data sources',
					value: 'has_source',
					type: 'toggle',
					tooltip: 'Does this task support automatic data collection sources?'
				},
				{
					label: 'Has Proving Methods',
					value: 'has_proving',
					type: 'toggle'
				},
				{
					label: 'Allow task to be repeatable',
					value: 'repeatable',
					type: 'toggle'
				},
				{
					label: 'Clear all group tasks when a single task is completed',
					value: 'clear_group',
					type: 'toggle'
				},
				{
					label: 'Supports List View in App',
					value: 'groupable',
					type: 'toggle'
				},
				{
					label: 'Hide task',
					value: 'hidden',
					type: 'toggle'
				},
				{
					label: 'Custom task',
					value: 'custom',
					type: 'toggle'
				},
				{
					label: 'Template Status',
					value: 'status',
					type: 'list',
					list: [
						{
							'name': 'Active',
							'value': 'active'
						},
						{
							'name': 'Draft',
							'value': 'draft'
						},
						{
							'name': 'Inactive',
							'value': 'inactive'
						},
						{
							'name': 'Archive',
							'value': 'archived'
						}
					]
				}
			]
		}
	},
	draft_template_configuration: {
		icon: 'fa-drafting-compass',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}?withConfiguration=1&withLiterals=1',
			post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/parameters/{id}',
			design: [
				{
					label: 'Template Id',
					value: 'id',
					name: 'template_id',
					type: 'banner'
				},
				{
					label: 'Template',
					value: 'name',
					type: 'banner'
				},
				{
					label: 'Display Proving Status in Task Title',
					value: 'configuration',
					key: 'showProving',
					name: 'showProving',
					type: 'switch',
					showIf: {
						'name': 'has_proving',
						'operator': 'equalTo',
						'value': 1
					}
				},
				{
					label: 'Components',
					value: 'id',
					name: 'components',
					add: '/component?template_id={id}',
					noadd: true,
					type: 'table',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/components/{id}',
						return: {
							'id': 'id',
							'name': 'label',
							'type': 'type',
							'value': 'id',
							'order': 'order'
						}
					},
					headers: [
						{
							text: 'Label', 
							value: 'name' 
						},
						{
							text: 'Type', 
							value: 'type' 
						},
						{
							text: 'Model', 
							value: 'model' 
						},
						{
							text: 'Order', 
							value: 'order' 
						},
						{
							text: 'Up', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}?move=up',
							icon: 'fa-sort-up'
						},
						{
							text: 'Down', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}?move=down',
							icon: 'fa-sort-down'
						},
						{ 
							text: 'Edit', 
							value: 'value',
							route: '/component/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Predicates',
					value: 'id',
					name: 'predicates',
					add: '/predicate?template_id={id}',
					noadd: true,
					type: 'table',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicate/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'id': 'id',
							'name': 'name',
							'value': 'id'
						}
					},
					headers: [
						{
							text: 'Name', 
							value: 'name' 
						},
						{ 
							text: 'Edit', 
							value: 'value',
							route: '/predicate/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Calculations',
					value: 'id',
					name: 'calculations',
					type: 'table',
					noadd: true,
					add: '/calculation?template_id={id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculation/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculations/{id}',
						return: {
							'id': 'id',
							'name': 'name',
							'value': 'id'
						}
					},
					headers: [
						{
							text: 'Name', 
							value: 'name' 
						},
						{ 
							text: 'Edit', 
							value: 'value',
							route: '/calculation/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Extra Data',
					value: 'id',
					name: 'extraData',
					type: 'table',
					noadd: true,
					add: '/extra_data?template_id={id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/extraData/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/extraDataList/{id}',
						return: {
							'id': 'id',
							'name': 'model',
							'value': 'id'
						}
					},
					headers: [
						{
							text: 'Model', 
							value: 'name',
						},
						{ 
							text: 'Edit', 
							value: 'id',
							route: '/extra_data/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Configuration',
					value: 'configuration',
					type: 'json'
				}
			]
		}
	},
	component: {
		icon: 'fa-puzzle-piece',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}',
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					showIf: {
						'name': 'template_id',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Parent',
					value: 'parent_id',
					name: 'Parent',
					type: 'banner',
					showIf: {
						'name': 'parent_id',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Type',
					value: 'type',
					type: 'list',
					list: [
						{
							'name': 'Tab Options',
							'value': 'TabOptions'
						},
						{
							'name': 'Single Line Text',
							'value': 'TextLineEntry'
						},
						{
							'name': 'Paragraph Text',
							'value': 'TextEntry'
						},
						{
							'name': 'Number Slider',
							'value': 'NumberSlider'
						},

						{
							'name': 'Time Entry',
							'value': 'TimeEntry'
						},
						{
							'name': 'Dropdown List',
							'value': 'DropdownList'
						},
						{
							'name': 'Row',
							'value': 'Row'
						},
						{
							'name': 'Button',
							'value': 'Button'
						},
						{
							'name': 'Buildable List',
							'value': 'StoredItemsList'
						},
						{
							'name': 'Date Entry',
							'value': 'DateEntry'
						},
						{
							'name': 'Radio Buttons',
							'value': 'RadioButtons'
						},
						{
							'name': 'Heading',
							'value': 'Heading'
						},
						{
							'name': 'Subheading',
							'value': 'Subheading'
						},
						{
							'name': 'Number Entry',
							'value': 'IntegerEntry'
						},
						{
							'name': 'Photo Upload',
							'value': 'PhotoUpload'
						}
					]
				},
				{
					label: 'Label',
					value: 'label',
				},
				{
					label: 'Model',
					value: 'model',
					showIf: {
						'name': 'type',
						'operator': 'notOneOf',
						'value': [
							'Row'
						]
					}
				},
				{
					label: 'Description',
					value: 'parameters',
					name: 'description',
					type: 'textarea',
					key: 'description',
					noadd: true,			
					showIf: {
						'name': 'type',
						'operator': 'notOneOf',
						'value': [
							'Heading',
							'Subheading',
							'Row'
						]
					}
				},
				{
					label: 'Required',
					value: 'required',
					type: 'toggle',
					default: {
						"show": true,
						"hide": false
					},
					showIf: {
						'name': 'type',
						'operator': 'notOneOf',
						'value': [
							'Heading',
							'Subheading'
						]
					}
				},
				{
					label: 'Required Message',
					value: 'parameters',
					name: 'requiredMessage',
					key: 'requiredMessage',
					noadd: true
				},
				{
					label: 'Problem Error Message',
					value: 'parameters',
					name: 'problemError',
					key: 'problemError',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'TimeEntry',
							'DateEntry',
							'NumberSlider',
							'DropdownList',
							'RadioButtons'
						]
					}
				},
				{
					label: 'Invalid Error Message',
					value: 'parameters',
					name: 'invalidError',
					key: 'invalidError',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'TimeEntry',
							'DateEntry',
							'NumberSlider',
							'DropdownList',
							'RadioButtons'
						]
					}
				},
				{
					label: 'Label Alternatives',
					value: 'labelAlternatives',
					type: 'table',
					noadd: true,
					add: '/label_alternative/{id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/labelAlternative/{id}',
					headers: [
						{
							text: 'Label', 
							value: 'label',
						},
						{ 
							text: 'Edit', 
							value: 'id',
							route: '/label_alternative/{id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Options',
					value: 'options',
					type: 'table',
					noadd: true,
					add: '/option/{id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}',
					headers: [
						{
							text: 'Label', 
							value: 'label' 
						},
						{
							text: 'Value', 
							value: 'value' 
						},
						{
							text: 'Order', 
							value: 'order' 
						},
						{
							text: 'Up', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}?move=up',
							icon: 'fa-sort-up'
						},
						{
							text: 'Down', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}?move=down',
							icon: 'fa-sort-down'
						},
						{ 
							text: 'Edit', 
							value: 'id',
							route: '/option/{id}',
							icon: 'fa-edit'
						}
					],
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'TabOptions',
							'DropdownList',
							'RadioButtons'
						]
					}
				},
				{
					label: 'Components',
					value: 'components',
					type: 'table',
					noadd: true,
					add: '/component?parent_id={id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}',
					headers: [
						{
							text: 'Label', 
							value: 'label' 
						},
						{
							text: 'Type', 
							value: 'type' 
						},
						{
							text: 'Model', 
							value: 'model' 
						},
						{
							text: 'Order', 
							value: 'order' 
						},
						{
							text: 'Up', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}?move=up',
							icon: 'fa-sort-up'
						},
						{
							text: 'Down', 
							value: 'id',
							update: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/component/{id}?move=down',
							icon: 'fa-sort-down'
						},
						{ 
							text: 'Edit', 
							value: 'id',
							route: '/component/{id}',
							icon: 'fa-edit'
						}
					],
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'Row'
						]
					}
				},
				{
					label: 'Start',
					value: 'parameters',
					name: 'start',
					key: 'start',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'NumberSlider'
						]
					}
				},
				{
					label: 'End',
					value: 'parameters',
					name: 'end',
					key: 'end',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'NumberSlider'
						]
					}
				},
				{
					label: 'Increment',
					value: 'parameters',
					name: 'increment',
					key: 'increment',
					noadd: true,
					default: "0.1",
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'NumberSlider',
						]
					}
				},
				{
					label: 'Default Setting',
					value: 'parameters',
					name: 'default',
					key: 'default',
					noadd: true,					
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'NumberSlider',
							'TabOptions',
							'DropdownList',
							'RadioButtons'
						]
					}
				},
				{
					label: 'Units',
					value: 'parameters',
					name: 'unit',
					key: 'unit',
					type: 'list',
					noadd: true,
					list: [
						{
							'name': 'None',
							'value': ''
						},
						{
							'name': 'Celcius (C)',
							'value': 'C'
						},
						{
							'name': 'Fahrenheit (F)',
							'value': 'F'
						},
						{
							'name': 'Aciditity (pH)',
							'value': 'pH'
						},
						{
							'name': 'Minutes (m)',
							'value': 'm'
						}
					],
					showIf: {
						'name': 'type',
						'operator': 'notOneOf',
						'value': [
							'Heading',
							'Subheading',
							'Row'
						]
					}
				},
				{
					label: 'Comment',
					value: 'parameters',
					name: 'comment',
					key: 'comment',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'NumberSlider'
						]
					}
				},
				{
					label: 'Data Source',
					value: 'parameters',
					name: 'dataSource',
					key: 'dataSource',
					type: 'list',
					noadd: true,
					list: [
						{
							'name': 'All Staff',
							'value': 'staff'
						},
						{
							'name': 'Only Staff with Signoff',
							'value': 'staffWithSignoff'
						},
						{
							'name': 'Suppliers',
							'value': 'suppliers'
						},
						{
							'name': 'Task with Thermometers',
							'value': 'thermometerTasks'
						},
						{
							'name': 'Batch Number',
							'value': 'batchNumber'
						}
					],
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'DropdownList'
						]
					}
				},
				{
					label: 'Data Source Value',
					value: 'parameters',
					name: 'dataSourceValue',
					key: 'dataSourceValue',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'DropdownList'
						]
					}
				},
				{
					label: 'Data Source Id Model',
					value: 'parameters',
					name: 'dataSourceIdModel',
					key: 'dataSourceIdModel',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'oneOf',
						'value': [
							'DropdownList'
						]
					}
				},
				{
					label: 'On Click',
					value: 'parameters',
					name: 'onclick',
					key: 'onclick',
					noadd: true,
					showIf: {
						'name': 'type',
						'operator': 'notOneOf',
						'value': [
							'Heading',
							'Subheading',
							'Row'
						]
					}
				},
				{
					label: 'Show Predicates',
					tooltip: 'If any of these predicates are true, this compoent will be SHOWN to the user.',
					value: 'predicates',
					name: 'showPredicates',
					key: 'showPredicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					}
				},
				{
					label: 'Problem Predicates',
					tooltip: 'If any of these predicates are true, this compoent will be changed to a PROBLEM state (orange).',
					value: 'predicates',
					name: 'problemPredicates',
					key: 'problemPredicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					}
				},
				{
					label: 'Invalid Predicates',
					tooltip: 'If any of these predicates are true, this compoent will be considered INVALID and the task will not be submittable.',
					value: 'predicates',
					name: 'invalidPredicates',
					key: 'invalidPredicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					}
				},
				{
					label: 'Required Predicates',
					tooltip: 'If any of these predicates are true, then this component will be REQUIRED to complete the task.',
					value: 'predicates',
					name: 'requiredPredicates',
					key: 'requiredPredicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					},
					showIf: {
						'name': 'required',
						'operator': 'equalTo',
						'value': false
					}
				}
			]
		}
	},
	predicate: {
		icon: 'fa-paperclip',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicate/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicate',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicate/{id}',
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
						return: 'name'
					}
				},
				{
					label: 'Name',
					value: 'name'
				},
				//{
				//	label: 'Notify For Issue',
				//	value: 'issue',
				//	type: 'toggle',
				//	tooltip: 'When set to true, if this predicate evaluates to true when completing a task, the diary entry will be saved as having an issue.',
				//	onValue: true,
				//	offValue: ''
				//},
				{
					label: 'Conditions',
					value: 'conditions',
					type: 'table',
					noadd: true,
					add: '/condition/{id}?template_id={template_id}',
					delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/condition/{id}',
					headers: [
						{
							text: 'Model', 
							value: 'model' 
						},
						{
							text: 'Comparator', 
							value: 'comparator' 
						},
						{
							text: 'Value', 
							value: 'value' 
						},
						{ 
							text: 'Edit', 
							value: 'id',
							route: '/condition/{id}?template_id={template_id}',
							icon: 'fa-edit'
						}
					]
				},
				{
					label: 'Note',
					value: 'When all of a predicate\'s conditions are TRUE, it can be used to \'require\', \'show\', and/or \'alert\' problems and errors with components to the user, and/or notify the diary that the task has an issue.',
					type: 'note'
				}
			]
		}
	},
	condition: {
		icon: 'fa-shield-alt',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/condition/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/condition/{id}',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/condition/{id}',
			design: [
				{
					label: 'Model', 
					value: 'model',
					type: 'select',
					id: 'template_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/components/{template_id}/models'
					}
				},
				{
					label: 'length',
					value: 'property',
					type: 'toggle',
					tooltip: 'If true, this will check the number of items in the model against the below settings',
					onValue: 'length',
					offValue: ''
				},
				{
					label: 'Comparator', 
					value: 'comparator',
					type: 'list',
					default: '==',
					list: [
						{
							name: 'equal to', 
							value: '==' 
						},
						{
							name: 'not equal to', 
							value: '!=' 
						},
						{
							name: 'greater than', 
							value: '>' 
						},
						{
							name: 'less than', 
							value: '<' 
						}
					]
				},
				{
					label: 'Type', 
					value: 'type',
					type: 'list',
					list: [
						{
							name: 'number', 
							value: 'number' 
						},
						{
							name: 'string', 
							value: 'string' 
						},
						{
							name: 'boolean', 
							value: 'boolean' 
						}
					]
				},
				{
					label: 'Value', 
					value: 'value' 
				},
				{
					label: 'If \'value\' starts with a $, this condition will use the component model of the same name for the comparison',
					type: 'note'
				}
			]
		}
	},
	option: {
		icon: 'fa-list-ul',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/option/{id}',
			design: [
				{
					label: 'Label', 
					value: 'label' 
				},
				{
					label: 'Type', 
					value: 'type',
					type: 'list',
					list: [
						{
							name: 'number', 
							value: 'number' 
						},
						{
							name: 'string', 
							value: 'string' 
						},
						{
							name: 'boolean', 
							value: 'boolean' 
						}
					]
				},
				{
					label: 'Allow the user to provide their own value?', 
					tooltip: 'If activated, the user will be give a text field to enter a custom value.  Any "value" you supply below will be used as instruction in the box.',
					value: 'custom',
					type: 'toggle',
					default: false
				},
				{
					label: 'Value', 
					value: 'value' 
				}
			]
		}
	},
	calculation: {
		icon: 'fa-calc',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculation/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculation',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculation/{id}',
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
						return: 'name'
					}
				},
				{
					label: 'Name',
					value: 'name',
				},
				{
					label: 'Calculation',
					value: 'calculation',
					type: 'list',
					list: [
						{
							name: 'subtract', 
							value: 'subtract' 
						},
						{
							name: 'max', 
							value: 'max' 
						},
						{
							name: 'min', 
							value: 'min' 
						}
					]
				},
				{
					label: 'Models',
					value: 'models',
					type: 'checklist',
					id: 'template_id',
					lookup: [
						{
							url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/components/{id}/?flat=1',
							return: {
								'name': 'model',
								'value': 'model'
							}
						},
						{
							url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/calculations/{id}',
							return: {
								'name': 'name',
								'value': 'name'
							}
						}
					]
				}
			]
		}
	},
	label_alternative: {
		icon: 'fa-calc',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/labelAlternative/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/labelAlternative/{id}',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/labelAlternative/{id}',
			design: [
				{
					label: 'Component',
					value: 'id',
					type: 'banner'
				},
				{
					label: 'Label',
					value: 'label',
				},
				{
					label: 'Predicates',
					value: 'predicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					}
				}
			]
		}
	},
	extra_data: {
		icon: 'fa-calc',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/extraData/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/extraData',
			patch: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/extraData/{id}',
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
						return: 'name'
					}
				},
				{
					label: 'Model',
					value: 'model',
				},
				{
					label: 'Value',
					value: 'value',
				},
				{
					label: 'Predicates',
					value: 'predicates',
					type: 'checklist',
					id: 'template_id',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/predicates/{id}',
						return: {
							'name': 'name',
							'value': 'name'
						}
					}
				}
			]
		}
	},
	check_template_configuration: {
		icon: 'fa-spell-check',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/check/{id}',
			noactions: true,
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					id: 'template_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
						return: 'name'
					}
				},
				{
					label: 'Published Version',
					value: 'version',
					type: 'banner'
				},
				{
					label: 'Published Schema',
					value: 'schema',
					type: 'banner'
				},
				{
					label: 'Last Published',
					value: 'published',
					type: 'banner'
				},
				{
					label: 'Pending Draft Changes Available!',
					value: 'has_draft_changes',
					type: 'note',
				},
				{
					label: 'See Pending Draft Changes',
					value: 'diff',
					type: 'json',
					showIf: {
						'name': 'has_draft_changes',
						'operator': 'notEmpty'
					}
				},				
				{
					label: 'Warnings',
					value: 'warnings',
					type: 'note',
					level: 'warning'
				},
				{
					label: 'Errors',
					value: 'errors',
					type: 'note',
					level: 'error'
				},
				{
					label: 'Success',
					value: 'success',
					type: 'note',
					level: 'success'
				},
				{
					label: 'Publish to LIVE',
					value: 'id',
					type: 'button',
					icon: 'fa-rocket',
					post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/publish/{id}',
					showIf: {
						'name': 'has_draft_changes',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Publish with Schema Changes',
					value: 'id',
					type: 'button',
					icon: 'fa-rocket',
					post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/publish/{id}?schema=1',
					showIf: {
						'name': 'has_draft_changes',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Components',
					value: 'components',
					type: 'json'
				},
				{
					label: 'Predicates',
					value: 'predicates',
					type: 'json'
				},
				{
					label: 'Draft Configuration',
					value: 'config',
					type: 'json'
				}
			]
		}
	},
	test_template_configuration: {
		icon: 'fa-vial',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			noactions: true,
			design: [
				{
					label: 'Template',
					value: 'name',
					name: 'template',
					type: 'banner'
				},
				{
					label: 'Choose a Test Venue',
					value: 'venue_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'venue',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues?status=training'
					}
				},
				{
					label: 'Launch Chef for Test Venue',
					value: 'company_id',
					type: 'button',
					icon: 'fa-vial',
					post: process.env.VUE_APP_API_BASE_URL + '/auth/v1/fernet/link?label=venueKeyDraft&clickable=1&ttl=' + fernetTokenValidityTtlSeconds + '&save=1&url=' + process.env.VUE_APP_QRCODE_BASE_URL,
					payload: '{ "companyId": "1010", "venueId": "<venue_id>", "draft": true }',
					showIf: {
						'name': 'venue_id',
						'operator': 'notEmpty'
					}
				}
			]
		}
	},
	publish_template_configuration: {
		icon: 'fa-rocket',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			noactions: true,
			design: [
				{
					label: 'Template',
					value: 'name',
					name: 'template',
					type: 'banner'
				},
				{
					label: 'Publish the Draft Configuration to Live',
					value: 'id',
					type: 'button',
					icon: 'fa-rocket',
					post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/publish/{id}'
				}
			]
		}
	},
	clone_template_configuration: {
		icon: 'fa-columns',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			post: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/copy/{id}',
			design: [
				{
					label: 'Destination Template',
					value: 'name',
					name: 'template',
					type: 'banner'
				},
				{
					label: 'Destination Template Id',
					value: 'id',
					name: 'template_id',
					type: 'banner'
				},
				{
					label: 'Select a Source Draft Template Configuration',
					value: 'source_template_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/templates'
					}
				},
				{
					label: 'Replace existing configuration?',
					value: 'replace',
					type: 'toggle',
					default: true
				}
			]
		}
	},
	versions_template_configuration: {
		icon: 'fa-code-branch',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/versions/{id}',
			headers: [
				{
					text: 'Version', 
					value: 'version' 
				},
				{
					text: 'Schema', 
					value: 'schema' 
				},
				{
					text: 'Published',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		},
	},	
	advanced_template_configuration: {
		icon: 'fa-microscope',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
			value: 'name',
			link: '/template_configuration/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configurations?template_id={id}',
			add: '/a_template_configuration?template_id={id}',
			delete: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/{id}',
			headers: [
				{
					text: 'Name', 
					value: 'name' 
				},
				{ 
					text: 'Value', 
					value: 'value'
				},
				{
					text: 'Type',
					value: 'type',
					icon: {
						'string': 'fa-font',
						'number': 'fa-hashtag',
						'boolean': 'fa-toggle-on',
						'list': 'fa-list',
						'object': 'fa-tag',
						'objects': 'fa-tags',
					},
				},
				{ 
					text: 'Configurable', 
					value: 'edittable',			
					icon: {
						1: 'fa-check-circle'
					}
				},
				{ 
					text: 'Edit', 
					value: 'id',					
					route: '/a_template_configuration/{id}',
					icon: 'fa-edit'
				}
			]
		},
	},	
	a_template_configuration: {
		icon: 'fa-microscope',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration',
			design: [
				{
					label: 'Template',
					value: 'template_id',
					name: 'template',
					type: 'banner',
					id: 'template_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template/{id}',
						return: 'name'
					}
				},
				{
					label: 'Parent Configuration',
					value: 'parent_configuration_id',
					type: 'autocomplete',
					autocomplete: {
						text: 'name',
						value: 'id',
						id: 'template_id',
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configurations?template_id={id}&can_parent=1'
					}
				},
				{
					label: 'Type',
					value: 'type',
					type: 'select',
					list: [
						{
							name: 'String',
							value: 'string'
						},
						{
							name: 'Number',
							value: 'number'
						},
						{
							name: 'True/False',
							value: 'boolean'
						},
						{
							name: 'Object',
							value: 'object'
						},
						{
							name: 'List of Values',
							value: 'list'
						},
						{
							name: 'List of Objects',
							value: 'objects'
						}
					]
				},
				{
					label: 'Name',
					value: 'name',
				},
				{
					label: 'Default Value',
					value: 'value'
				},
				{
					label: 'Configurable',
					value: 'edittable',
					type: 'toggle',
					default: false
				},
				{
					label: 'Configuration Output',
					value: 'output',
					type: 'json',
					id: 'template_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/tasks/v1/template_configuration/build?template_id={id}'
					}
				}
			]
		}
	},
	source: {		
		icon: 'fa-cloud',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/source',
			design: [
				{
					label: 'Source Name',
					value: 'name'
				},
				{
					label: 'Type',
					value: 'namespace',
					type: 'list',
					list: [
						{
							'name': 'CleverLogger',
							'value': 'CleverLogger',
						},
						{
							'name': 'Ozark',
							'value': 'Ozark',
						},
						{
							'name': 'Spark',
							'value': 'Spark',
						}
					]
				}
			]
		}
	},
	source_users: {
		icon: 'fa-id-card',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/source/users/{id}',
			add: '/source_user',
			headers: [
				{
					text: 'Username',
					value: 'username',
					id: 'id',
					route: '/source_user/{id}'
				}
			]
		}
	},
	source_user: {		
		icon: 'fa-id-card',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/source/user/{id}',
			design: [
				{
					label: 'Source',
					value: 'source_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/source/{id}',
						return: 'name'
					},
					readonly: true
				},
				{
					label: 'Username',
					value: 'username',
				},

				{
					label: 'Password',
					value: 'password',
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	venue_reading_devices: {
		icon: 'fa-thermometer-quarter',
		subtitle: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venue/{id}',
			value: 'name',
			link: '/venue/{id}'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/devices?venueId={id}',
			headers: [
				{
					text: 'Reading Device',
					value: 'name',
					id: 'id',
					route: '/reading_device/{id}'
				},
				{
					text: 'Alias',
					value: 'alias'
				},
				{
					text: 'Source Type',
					value: 'source_user_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/sources',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Last Reading (C)',
					value: 'id',
					name: 'last_reading',
					route: '/reading/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'average'
					}
				},
				{
					text: 'Last Reading Timestamp',
					value: 'id',
					name: 'timestamp',
					route: '/reading/{id}',
					convert: 'datetime',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'timestamp'
					}
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'inactive': 'fa-times',
						'deleted': 'fa-ban'
					}
				}
			]
		}
	},
	reading_devices: {
		icon: 'fa-thermometer-quarter',
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/devices?sourceUserId={id}',
			headers: [
				{
					text: 'Reading Device',
					value: 'alias',
					id: 'id',
					route: '/reading_device/{id}'
				},
				{
					text: 'Alias',
					value: 'alias'
				},
				{
					text: 'Venue',
					value: 'venue_id',
					id: 'venue_id',
					route: '/venue/{id}',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Last Reading (C)',
					value: 'id',
					name: 'last_reading',
					route: '/reading/{id}',
					convert: 'datetime',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'average'
					}
				},
				{
					text: 'Last Reading Timestamp',
					value: 'id',
					name: 'timestamp',
					route: '/reading/{id}',
					convert: 'datetime',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
						search: 'device_id',
						return: 'timestamp'
					}
				},
				{
					text: 'History',
					value: 'id',
					icon: 'fa-chart-line',
					route: '/reading_device_history/{id}'
				},
				{
					text: 'Status',
					value: 'status',
					icon: {
						'active': 'fa-check',
						'inactive': 'fa-times',
						'deleted': 'fa-ban'
					}
				}
			]
		}
	},
	reading_device_history: {
		icon: 'fa-chart-line',
		subtitle: {
			value: 'Reading Devices',
			link: '/reading_devices'
		},
		graph: {
			title: "Readings History",
			type: "LineChart",
			data: [
				{
					legend: 'Date',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}?count=144',
					parse_rule: 'timestamp'
				},
				{
					legend: 'Temperature',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}?count=144',
					parse_rule: 'average'
				},
				{
					legend: 'Signal Strength',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}?count=144',
					parse_rule: 'max'
				},
				{
					legend: 'Battery %',
					url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}?count=144',
					parse_rule: 'max'
				}
			]
		}
	},
	reading_device: {
		icon: 'fa-thermometer-quarter',
		help: "HELEPEPLEPLEPLEPE!!!!",
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/device/{id}',
			design: [
				{
					label: 'Chomp Alias',
					value: 'alias'
				},
				{
					label: 'Venue',
					value: 'venue_id',
					type: 'autocomplete',
					autocomplete: {
						name: 'venue',
						text: 'name',
						value: 'id',
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/venues'
					}
				},
				{
					label: 'Source Device Name',
					value: 'name',
					readonly: true
				},
				{
					label: 'Source Device Id',
					value: 'source_device_id',
					readonly: true
				},
				{
					label: 'Status',
					value: 'status',
					type: 'list',
					list: [
						{
						'name': 'Inactive',
						'value': 'inactive',
						},
						{
						'name': 'Active',
						'value': 'active',
						},
						{
						'name': 'Deleted',
						'value': 'deleted',
						}
					]
				},
			]
		},
	},
	reading: {
		icon: 'fa-snowflake',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/reading/{id}',
			design: [
				{
					label: 'Device (alias)',
					value: 'device_id',
					readonly: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/readings/v2/device/{id}',
						return: 'alias'
					}
				},
				{
					label: 'Last Reading Timestamp',
					value: 'timestamp',
					convert: 'timestamp',
					readonly: true
				},
				{
					label: 'Temperature',
					value: 'average',
					readonly: true
				},
				{
					label: 'Signal (db)',
					value: 'min',
					readonly: true
				},
				{
					label: 'Battery (%)',
					value: 'max',
					readonly: true
				},
				{
					label: 'Units',
					value: 'units',
					readonly: true
				},
				{
					label: 'Time Window (seconds)',
					value: 'window',
					readonly: true
				}
			]
		}
	},
	security: {
		icon: 'fa-shield-alt',
		subtitle: {
			value: 'Admin',
			link: '/admin'
		},
		list: {
			items: [
				{ 
					header: 'Please use the following links to manager user security:' 
				},
				{
					icon: 'fa-users',
					title: 'Users',
					subtitle: ' User manager to add, update and remove users to Chomp software',
					path: '/users'
				},
				{
					icon: 'fa-user-circle',
					title: 'Roles',
					subtitle: ' Add, update and delete roles for controlling user access to Chomp software',
					path: '/roles'
				},
				{
					icon: 'fa-key',
					title: 'Service Clients',
					subtitle: ' Add, update and delete client credentials for OAuth Service access',
					path: '/service_clients'
				},
				{
					icon: 'fa-lock-open',
					title: 'Scopes',
					subtitle: ' Add, update and delete scopes for service level access to Chomp software',
					path: '/scopes'
				}
			]
		}
	},
	users: {
		icon: "fa-users",
		subtitle: {
			value: 'Security',
			link: '/security'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/users',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}',
			headers: [
				{
					text: 'Username',
					value: 'uuid',
					name: 'email',
					id: 'uuid',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
						search: 'id',
						return: 'email',
						name: 'email'
					}
				},
				{
					text: 'UUID',
					value: 'uuid',
					id: 'id',
					route: '/user/{id}'
				},
				{
					text: 'Change Password',
					value: 'uuid',
					id: 'uuid',
					route: '/change_password/{id}',
					icon: 'fa-user-lock'
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	user: {
		icon: 'fa-user',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}',
			update: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}/roles',
			design: [
				{
					label: 'Profile',
					value: 'uuid',
					name: 'profile_email',
					type: 'banner',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
						return: 'email'
					}
				},
				{
					label: 'Legacy User',
					value: 'legacy_id',
					type: 'banner',
					noadd: true,
					showIf: {
						'name': 'legacy_id',
						'operator': 'notEmpty'
					}
				},
				{
					label: 'Roles',
					value: "Roles can only be set on existing users.  Once the user has been created, navigate to the user's edit page and set the appropriate role(s).",
					type: 'note',
					noedit: true,
				},
				{
					label: 'Generate a new password (an email we be sent to the user with a one-time use password)',
					value: 'generate',
					type: 'toggle',
					noedit: true
				},
				{
					label: 'Send Password Reset Email',
					type: 'button',
					value: 'uuid',
					id: 'uuid',
					noadd: true,
					put: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/reset'
				},				
				{
					label: 'Select Role(s)',
					value: 'roles',
					type: 'checklist',
					noadd: true,
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/roles',
						return: {
							'name': 'label',
							'value': 'id'
						}
					}
				},
				{
					label: 'Email User after Update',
					value: 'notify',
					type: 'toggle',
					noadd: true,
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	legacy_users: {
		icon: "fa-user-nurse",
		subtitle: {
			value: 'Admin',
			link: '/admin'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/users?legacy=1&orderBy=created_at&direction=desc',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}?legacy=1',
			prefill: false,
			headers: [
				{
					text: 'First Name',
					value: 'first_name',
					route: '/legacy_user/{id}'
				},
				{
					text: 'Last Name',
					value: 'last_name',
					route: '/legacy_user/{id}'
				},
				{
					text: 'Email',
					value: 'email',
					uniqueKey: true
				},
				{
					text: 'Company',
					value: 'company_id',
					lookup: {
						prefetch: true,
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies?simple=1',
						search: 'id',
						return: 'name'
					}
				},
				{
					text: 'Archived',
					value: 'old_email',
					icon: 'fa-archive'
				},
				{
					text: 'Super Admin',
					value: 'super_admin',
					icon: {
						1: 'fa-check'
					}
				},
				{
					text: 'Migrate!',
					value: 'migrated',
					route: '/migrate_user/{id}',
					icon: {
						0: 'fa-people-carry',
						1: ''
					}
				},
				{
					text: 'Created',
					value: 'created_at',
					convert: 'datetime'
				}
			]
		}
	},
	legacy_user: {
		icon: 'fa-people-arrows',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}?legacy=1&withVenues=1',
			design: [
				{
					label: 'First Name',
					value: 'first_name',
				},
				{
					label: 'Last Name',
					value: 'last_name',
				},
				{
					label: 'Email',
					value: 'email',
				},
				{
					label: 'Old Email',
					value: 'old_email',
					readonly: true
				},
				{
					label: 'Company',
					value: 'company_id',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/company/{id}',
						return: 'name'
					},
					readonly: true
				},
				{
					label: 'Venues',
					value: 'venues',
					type: 'list',
					readonly: true
				},
				{
					label: 'Receive Email',
					value: 'receive_emails',
					type: 'toggle'
				},
				{
					label: 'Bulk Email',
					value: 'bulk_email',
					type: 'toggle'
				},
				{
					label: 'Sign off',
					value: 'app_sign_off',
					type: 'toggle'
				},
				{
					label: 'Roles',
					value: 'roles',
					type: 'list',
					readonly: true
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	migrate_user: {
		icon: 'fa-people-carry',
		subtitle: {
			value: 'Legacy Users',
			link: '/legacy_users'
		},
		page: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/user/{id}?legacy=1&migrate=1',
			content: '<p><h2 align="center">User {first_name} {last_name} migrated successfully!</h2></p>'
		}
	},
	change_password: {
		icon: 'fa-user-lock',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
			update: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}/password',
			design: [
				{
					label: 'First Name',
					value: 'first_name',
					readonly: true
				},
				{
					label: 'Last Name',
					value: 'last_name',
					readonly: true
				},
				{
					label: 'Email',
					value: 'email',
					readonly: true
				},
				{
					label: 'Current Password',
					value: 'current',
					type: 'password'
				},
				{
					label: 'New Password',
					value: 'new',
					type: 'password'
				},
				{
					label: 'Confirm Password',
					value: 'confirm',
					type: 'password'
				},
			]
		}
	},
	password_reset: {
		icon: 'fa-user-lock',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}',
			update: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/{id}/password',
			design: [
				{
					label: 'First Name',
					value: 'first_name',
					readonly: true
				},
				{
					label: 'Last Name',
					value: 'last_name',
					readonly: true
				},
				{
					label: 'Email',
					value: 'email',
					readonly: true
				},
				{
					label: 'Reset',
					value: 'reset',
					type: 'hidden'
				},
				{
					label: 'New Password',
					value: 'new',
					type: 'password'
				},
				{
					label: 'Confirm Password',
					value: 'confirm',
					type: 'password'
				},
			]
		}
	},
	request_reset: {
		icon: 'fa-user-lock',
		form: {
			update: process.env.VUE_APP_API_BASE_URL + '/auth/v1/profile/reset',
			design: [
				{
					label: 'Request Password Reset Link',
					value: "Please enter the email address that you use to access your Chomp account and we wil send you a password reset link.",
					type: 'note'
				},
				{
					label: 'Email',
					value: 'email'
				},
			]
		}
	},
	service_clients: {
		icon: "fa-key",
		subtitle: {
			value: 'Security',
			link: '/security'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/clients',
			add: '/service_client',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/client/{id}',
			headers: [
				{
					text: 'Service Client Name',
					value: 'name',
					id: 'id',
					route: '/service_client/{id}'
				},
				{
					text: 'Status',
					value: 'revoked',
					icon: {
						0: 'fa-check',
						1: 'fa-times'
					}
				},
				{
					text: 'Type',
					value: 'password_client',
					icon: {
						1: 'fa-user-secret'
					}
				},
				{
					text: 'Created',
					value: 'created_at'
				}
			]
		}
	},
	service_client: {
		icon: 'fa-key',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/client/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/auth/v1/client',
			design: [
				{
					label: 'Service Client Name',
					value: 'name'
				},
				{
					label: 'Client Id',
					value: 'id',
					noadd: true,
					readonly: true
				},
				{
					label: 'Secret',
					value: 'secret',
					type: 'password',
					noadd: true,
					readonly: true
				},
				{
					label: 'Disabled',
					value: 'revoked',
					noadd: true,
					type: 'toggle'
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	create_client: {
		icon: 'fa-key',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/client/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/auth/v1/client',
			design: [
				{
					label: 'Client Name',
					value: 'name'
				}
			]
		}
	},
	scopes: {
		icon: 'fa-local-open',
		subtitle: {
			value: 'Security',
			link: '/security'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/scopes',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/scope/{id}',
			add: '/scope',
			headers: [
				{
					text: 'Scope',
					value: 'scope',
					id: 'id',
					route: '/scope/{id}'
				},
				{
					text: 'Created',
					value: 'created_at',
					type: 'datetime'
				}
			]
		}
	},
	scope: {
		icon: 'fa-lock-open',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/scope/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/auth/v1/scope',
			design: [
				{
					label: 'Scope',
					value: 'scope'
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	roles: {
		icon: 'fa-user-circle',
		subtitle: {
			value: 'Security',
			link: '/security'
		},
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/roles',
			add: '/role',
			delete: process.env.VUE_APP_API_BASE_URL + '/auth/v1/role/{id}',
			headers: [
				{
					text: 'Role',
					value: 'label',
					id: 'id',
					route: '/role/{id}'
				},
				{
					text: 'Created',
					value: 'created_at',
					type: 'datetime'
				}
			]
		}
	},
	role: {
		icon: 'fa-user-circle',
		form: {
			url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/role/{id}',
			add: process.env.VUE_APP_API_BASE_URL + '/auth/v1/role',
			design: [
				{
					label: 'Name',
					value: 'name'
				},
				{
					label: 'Label',
					value: 'label'
				},
				{
					label: 'Scopes',
					value: 'scopes',
					type: 'checklist',
					lookup: {
						url: process.env.VUE_APP_API_BASE_URL + '/auth/v1/scopes',
						return: {
							'name': 'scope',
							'value': 'id'
						}
					}
				},
				{
					label: 'Created',
					value: 'created_at',
					type: 'datetime'
				},
				{
					label: 'Last Updated',
					value: 'updated_at',
					type: 'datetime'
				}
			]
		}
	},
	documentation: {
		icon: 'fa-book',
		subtitle: {
			value: 'Admin',
			link: '/admin'
		},
		list: {
			items: [
				{ 
				header: 'Choose a documentation link:' 
				},
				{
				icon: 'fa-utensils',
				title: 'Venue Service',
				subtitle: ' API Documentation for accessing and managing Venue and Company records',
				path: 'https://venues.chomp.nz'
				},
				{
				icon: 'fa-clipboard-list',
				title: 'Task Service',
				subtitle: ' API Documentation for accessing and managing Tasks and Task Template records',
				path: 'https://tasks.chomp.nz'
				},
				{
				icon: 'fa-clipboard-check',
				title: 'Diary Service',
				subtitle: " API Documentation for accessing and managing Diaries and Diary Entry records",
				path: 'https://diaries.chomp.nz'
				},
				{
				icon: 'fa-thermometer-quarter',
				title: 'Readings Service',
				subtitle: " API Documentation for accessing and managing Sources, Devices and Readings records",
				path: 'https://readings.chomp.nz'
				},
				{
				icon: 'fa-lock',
				title: 'Auth Service',
				subtitle: " API Documentation for accessing and managing Users, Developer and Security records",
				path: 'https://auth.chomp.nz'
				},
				{
				icon: 'fa-truck',
				title: 'Supplier Service',
				subtitle: " API Documentation for accessing and managing Suppliers and Supplier Certificates",
				path: 'https://suppliers.chomp.nz'
				},
				{
				icon: 'fa-tablet-alt',
				title: 'App Service',
				subtitle: " API Documentation for managing communications from Chomp apps (ie. chef and kaitoa)",
				path: 'https://apps.chomp.nz'
				}
			]
		}
	},
	table_feature_development: {
		icon: "fa-building",
		table: {
			url: process.env.VUE_APP_API_BASE_URL + '/venues/v1/companies',
			title: "Application Data Table Development View",
			description: "Just a... vue... with some funky table options to support development, not to be used for anything else",
			prefill: false,
			customFilters: [
				{		
					// What type of custom filter is this?
					type: 'dateRange',
					// Descriptive text to prompt the user (note: 'created_at' specific here so change as appropriate)
					heading: 'Table Date Range Filter',
					subheading: 'Only items created within this range will be returned',
					// Pre-configured common date ranges where value is DAYS, so programmatic date range option length would be: "(NOW - DAYS) to NOW"
					shortcuts: [
						{title: 'past 24 hours', value: 1},
						{title: 'past 3 days', value: 3},
						{title: 'past week', value: 7},
						{title: 'past two weeks', value: 14},
						{title: 'past 30 days', value: 30},
						{title: 'past year', value: 365},
					],
					// Forms the API request that we would like the date filtering performed on (e.g. 'created_at', 'modified_at', 'last_active' ...)
					filterKey: 'created_at',
					propertyName: 'date_filter',
					// Forms the API request and allows for the range parameters to be rebound for certain endpoints (if necessary)
					startDate: 'from',
					endDate: 'to',
				},
			],
			headers: [
				{
					text: 'Companies',
					value: 'name',
					route: '/company/{id}',
				},
				{
					text: 'HubSpot Company',
					value: 'hubspot_company_id',
					route: 'https://app.hubspot.com/contacts/3019751/company/{id}'
				},
				{
					text: 'Company Dashboard',
					value: 'id',
					route: process.env.VUE_APP_SETUP_BASE_URL + '/companies/{id}',
					icon: 'fa-user-cog',
					uniqueKey: true
				},
				{
					text: 'Venues',
					value: 'venueCount',
					route: '/company_venues/{id}'
				},
				{
					text: 'Employees',
					value: 'employeeCount',
					route: '/company_employees/{id}'
				},
				{
					text: 'Legacy Users',
					value: 'id',
					icon: 'fa-user-lock',
					route: '/company_legacy_users/{id}'
				}
			]
		}
	},
}
