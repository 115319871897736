<template>
  <v-alert v-if="validationErrorsList.length" title="Error Summary" class="border-error" variant="outlined">
    <p>The following errors must be fixed before the form can be submitted</p>
    <v-list :items="validationErrorsList" lines="one">
      <v-list-item
        v-for="(errorMessage, index) in validationErrorsList"
        :key="index"
        :title="errorMessage"
        density="compact"
        prepend-icon="fas fa-circle"
        slim
      ></v-list-item>
    </v-list>
  </v-alert>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'BaseFormValidationSummary',
  props: {
    validationErrors: {
      type: Object,
    }
  },
  setup(props) {  
    const validationErrorsList = ref([])


    watch(() => props.validationErrors, () => {
      validationErrorsList.value = Object.values(props.validationErrors)
    })

    return {validationErrorsList}
  }
}
</script>
