<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="selected"
          :disabled="formItem.readonly || loading"
          :items="items"
          :item-title="formItem.autocomplete.text"
          :item-value="formItem.autocomplete.value"
          :prepend-icon="'fas ' + (formItem.autocomplete.icon ? formItem.autocomplete.icon : 'fa-list')"
          :label="formItem.label"
          :clearable="formItem.autocomplete.clearable"
          hide-details>
          <template v-slot:item="{ props }">
            <v-list-item
              v-bind="props"
              :subtitle="props.value"
              :title="props.title"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { common } from '../mixins/common'
import { useHttpCommon } from '../composables/useHttpCommon'

export default {
  mixins: [common],
  data: function () {
    return {
      search: '',
      selected: '',
      autoCompleteData: [],
      items: [],
      loading: true
    }
  },
  props: {
    formItem: {
      type: Object
    },
    defaultValue: {
      type: [Object, String, Number]
    },
    formUrl: {
      type: String
    }
  },
  watch: {
    formUrl: {
      immediate: true,
      handler(val) {
        if (typeof val !== "undefined") {
          this.getAutoCompleteData(val)
        }
      }
    },
    defaultValue: {
      handler(val) {
        if (typeof val !== "undefined") {
          this.selected = val
        }
      }
    },
    search: {
      handler(val) {
        !this.loading && val && val !== this.select && this.querySelections(val)
      }
    },
    selected: {
      handler(val) {
        this.$emit('formSelection', val)
        this.emitSuggestedValue(val)
      }
    }
  },
  methods: {
    emitSuggestedValue(val) {
      if (this.formItem.autocomplete.suggestions) {
        // 'value' is the _value_ which we would like to use as a suggested value for a sibling control ('target')
        const value = this.items.find(i => i[this.formItem.autocomplete.value] === val)?.[this.formItem.autocomplete.suggestions.sourceKey];
        const target = this.formItem.autocomplete.suggestions.targetKey;
        const applyWhen = this.formItem.autocomplete.suggestions.applyWhen;
        if (value) {
          this.$emit('suggestion', {value, target, applyWhen})
        }
      }
    },
    querySelections(v) {
      this.items = this.autoCompleteData.filter(e => {
        return (e[this.acText] || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
      })
    },
    getAutoCompleteData(url) {
      const { ajax } = useHttpCommon()
      ajax.get(url, {})
        .then(response => {
          this.loading = false
          this.items = response.data
          this.autoCompleteData = response.data
        })
        .catch(e => {
          console.log(e)
          this.error(e)
        })
    },
  }
}
</script>
