<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          location="bottom"
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              :model-value="formattedDate"
              :label="label"
              prepend-icon="fas fa-calendar"
              :readonly="readOnly"
              v-bind="props"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="internalDate"
            min="1950-01-01"
            @update:model-value="updateDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import moment from 'moment'

const props = defineProps({
  modelValue: {
    type: [String, Date, null, Boolean], // TODO: fix this to only accept proper data types
    default: null
  },
  outputValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: null
  },
  outputFormat: {
    type: String,
    default: 'YYYY-MM-DD'
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'update:outputValue'])

const menu = ref(false)
const internalDate = ref(null)

const formattedDate = computed(() => {
  return internalDate.value ? moment(internalDate.value).format('YYYY-MM-DD') : ''
})

const outputValue = computed(() => {
  return internalDate.value ? moment(internalDate.value).format(props.outputFormat) : ''
})

const updateDate = (newDate) => {
  menu.value = false
  const date = newDate ? moment(newDate).toDate() : null
  internalDate.value = date
  emit('update:modelValue', date)
  emit('update:outputValue', outputValue.value)
}

watch(() => props.modelValue, (newValue) => {
  internalDate.value = newValue ? moment(newValue).toDate() : null
  emit('update:outputValue', outputValue.value)
}, { immediate: true })

// Emit initial output value
emit('update:outputValue', outputValue.value)
</script>