<template>
  <v-container class="preferences-page">
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 mb-6">User Preferences</h1>

        <v-card>
          <v-card-text>
            <v-select
              v-model="preferences.theme"
              :items="themeOptions"
              label="Theme"
              @update:model-value="updatePreference('theme', $event)"
            ></v-select>

            <v-btn
              color="primary"
              @click="resetPreferences"
              class="mt-4"
            >
              Reset to Defaults
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { useUserPreferences } from '@/composables/userPreferences'

  const { preferences, updatePreference, resetPreferences } = useUserPreferences()
  const themeOptions = [
    { title: 'Light', value: 'light' },
    { title: 'Dark', value: 'dark' },
  ]
</script>

<style scoped>
.preferences-page {
  max-width: 600px;
  margin: 0 auto;
}
</style>