<template>
    <!-- JSON OUTPUT -->
    <v-expansion-panels class="pa-2 my-3" fluid>
        <v-expansion-panel>
            <v-expansion-panel-title>
                {{ label }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <vue-json-pretty :data="data"></vue-json-pretty>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import 'vue-json-pretty/lib/styles.css'
    export default {
        data: function () {
            return {
                data: null
            }
        },
        props: {
            jsonObject: {
                type: [ Object, Array, String ]
            },
            label: {
                type: String
            }
        },
        components: {
            VueJsonPretty
        },
        watch: {
            jsonObject: {
                immediate: true,
                handler (val) {
                    if (typeof val !== "undefined") {
                        if (typeof val === "string") {
                            this.data = JSON.parse(val)
                        } else {
                            this.data = val
                        }
                    }
                }
            }
        }
    }
</script>