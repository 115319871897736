<template>
    <!-- CHECKLIST -->
    <v-container  class="pa-2 my-3" fluid>
        <div slot="label"> 
            {{ label }}
            <v-tooltip location="bottom" v-if="tooltip">
                <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">fas fa-question-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </div>
        <v-row no-gutters>
          <v-col cols="auto" v-for="n in items" :key="n.name">
            <v-checkbox v-model="checkedBoxes" :label="n.name" :value="n.value"></v-checkbox>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                checkedBoxes: []
            }
        },
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            items: {
                type: Array
            },
            selected: {
                type: Array
            }
        },
        watch: {
            selected: {
                handler (val) {
                    this.checkedBoxes = val
                }
            },
            checkedBoxes: {
                handler (val) {
                    this.$emit('checked', val)
                }
            }
        }
    }
</script>