import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { useUserPreferences } from '@/composables/userPreferences'
import { en } from 'vuetify/locale';
import '@fortawesome/fontawesome-free/css/all.css'

const { preferences } = useUserPreferences()

const vuetifyOptions = {
  components: { VTimePicker, VDateInput, ...components },
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    variations: {
      colors: ['primary'],
      lighten: 5
    },
    defaultTheme: preferences.value.theme || 'light',
    themes: {
      light: {
        colors: {
          primary: process.env.VUE_APP_COLOR_PRIMARY,
        },
      },
      dark: {
        colors: {
          primary: process.env.VUE_APP_COLOR_PRIMARY,
        },
      },
    },
  },
  locale: {
    // Use the en-NZ locale so date formatting is as expected
    locale: 'en-NZ',
    fallback: 'en',
    messages: {
      // Clone the en translation for en-NZ
      'en-NZ': {
        ...en,
        $vuetify: {
          ...en.$vuetify,
        }
      }
    }
  },
}
export default createVuetify(vuetifyOptions);
