<template>
  <v-toolbar flat color="primary" dark>
    <v-toolbar-title><v-icon class="mr-3" size="large">fas {{ icon }}</v-icon>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- CVS DATA DOWNLOAD -->
    <v-tooltip v-if="tableData" location="bottom">
      <template v-slot:activator="{ props }">
        <download-csv :data="tableData" :name="title">
          <v-btn class="mx-3" v-bind="props" icon>
            <v-icon size="large">fas fa-file-export</v-icon>
          </v-btn>
        </download-csv>
      </template>
      download as csv
    </v-tooltip>
    <!-- DATA SOURCE DETAILS -->
    <v-tooltip v-if="dataUrls" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn class="mx-3" icon @click="dataDialog = true">
          <v-icon size="large">fas fa-code</v-icon>
        </v-btn>
        <v-dialog
          v-model="dataDialog"
          width="700"
          >
          <v-card>
            <v-card-title>
              <v-tooltip location="bottom">
                <v-btn icon v-clipboard:copy="props.jwtToken" v-on="on">
                  <v-icon>fas fa-user-shield</v-icon>
                </v-btn>
                copy JWT token to clipboard
              </v-tooltip>
              <span v-for="(dataUrl, index) in dataUrls" :key="index">
                <span class="text-h6 font-weight-light text-center">{{ dataUrl }}</span>
                <v-tooltip location="bottom">
                  <v-btn icon v-clipboard:copy="props.dataUrl">
                    <v-icon>fas fa-copy</v-icon>
                  </v-btn>
                  copy url to clipboard
                </v-tooltip>
              </span>
            </v-card-title>
            <v-card-text>
              <vue-json-pretty :data="data"></vue-json-pretty>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      see Service Response
    </v-tooltip>
    <!-- HELP DIALOG -->
    <v-tooltip v-if="help" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn class="mx-3" v-bind="props" icon @click="helpDialog = true">
          <v-icon size="large">fas fa-question</v-icon>
        </v-btn>
        <v-dialog
          v-model="helpDialog"
          width="700"
          >
          <v-card>
            <v-card-title class="bg-grey-lighten-2">
              Page Help
            </v-card-title>
            <v-card-text>
              {{ help }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <span>Help?</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css';
  export default {
    data () {
      return {
        dataDialog: false,
        helpDialog: false,
        jsonData: [],
      }
    },
    props: {
      icon: String,
      title: String,
      help: String,
      search: String,
      data: Object,
      dataUrls: Array,
      jwtToken: String
    },
    components: {
        VueJsonPretty,
        // VueJsonToCsv
    },
    computed: {
      tableData: function() {
        var cleanData
        if (this.data.data) {
          cleanData = this.data.data
        } else {
          cleanData = this.data
        }
        if (Array.isArray(cleanData) && cleanData.length > 0) {
          return cleanData
        } else {
          return null
        }
      }
    }
  }
</script>