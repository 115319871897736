import { ref, watch } from 'vue'
import { useAuth } from '@/composables/useAuth'

export function useUserPreferences() {
  const { getUserUUID } = useAuth();
  const STORAGE_KEY = `user_preferences_${getUserUUID() || 'anon'}`

  // Default preferences
  const defaultPreferences = {
    baseTableFilterDateRangeExpansionPanelState: true, // Open 
    theme: 'light',
    navigationRail: false,
    navigationDrawer: true,
    pageSize: 10
  }

  // Initialize preferences from localStorage or use defaults
  const preferences = ref(loadPreferences())

  // Watch for changes and save to localStorage
  watch(preferences, savePreferences, { deep: true })

  function loadPreferences() {
    const stored = localStorage.getItem(STORAGE_KEY)
    if (stored) {
      try {
        return JSON.parse(stored)
      } catch (e) {
        console.error('Failed to parse stored preferences:', e)
      }
    }
    return { ...defaultPreferences }
  }

  function savePreferences() {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(preferences.value))
  }

  function updatePreference(key, value) {
    if (key in preferences.value || key in defaultPreferences) {
      preferences.value[key] = value
    } else {
      console.warn(`Preference key "${key}" does not exist, creating`)
      preferences.value[key] = value
    }
  }

  function resetPreferences() {
    preferences.value = { ...defaultPreferences }
  }

  return {
    preferences,
    updatePreference,
    resetPreferences
  }
}