<template>
  <div class="authorized-image">
    <img v-if="loadedImageUrl" :src="loadedImageUrl" :alt="alt" class="image" />
    <div v-else-if="error" class="error-message">{{ error }}</div>
    <div v-else class="loading">Loading...</div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import ajax from '@/composables/useHttpCommon';

export default {
  name: 'BaseSecureImage',
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: 'Image'
    }
  },
  setup(props) {
    const loadedImageUrl = ref(null)
    const error = ref(null)

    const loadImage = async () => {
      if (loadedImageUrl.value) {
        URL.revokeObjectURL(loadedImageUrl.value)
      }
      loadedImageUrl.value = null
      error.value = null

      try {
        const response = await ajax.get(props.imageUrl, {
          responseType: 'blob',
        })
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        loadedImageUrl.value = URL.createObjectURL(blob)
      } catch (err) {
        console.error('Error loading image:', err)
        error.value = 'Failed to load image'
      }
    }

    onMounted(() => {
      loadImage()
    })

    onUnmounted(() => {
      if (loadedImageUrl.value) {
        URL.revokeObjectURL(loadedImageUrl.value)
      }
    })

    watch(() => props.imageUrl, () => {
      loadImage()
    })

    return {
      loadedImageUrl,
      error
    }
  }
}
</script>

<style scoped>
.authorized-image {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.image {
  width: 100%;
  height: auto;
}
.error-message {
  color: red;
  font-weight: bold;
}
.loading {
  font-style: italic;
}
</style>