<template>
    <!-- Note -->
    <v-container class="pa-2 my-3" fluid v-if="show">
        <v-alert
            :type="type"
            variant="outlined"
        >
            <h5 class="text-h5 pa-2" v-if="label">{{ label }}</h5>
            <span v-html="output"></span>
        </v-alert>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                output: '',
                show: false
            }
        },
        props: {
            label: {
                type: String
            },
            message: {
                type: [ String, Object, Array ]
            },
            type: {
                type: String,
                default: 'info'
            }         
        },
        watch: {
            message: {
                immediate: true,
                handler (val) {
                    if (typeof val === 'string' || val instanceof String) {
                        this.output = val
                        this.show = true
                    } else if (val instanceof Array) {
                        for (var item in val) {
                            this.output = this.output + "<br />" + val[item]
                        }
                        this.show = true
                    }
                }
            }
        }
    }
</script>