<template>
  <v-container class="pa-2 my-3" fluid>
    <div>
      {{ label }}
      <v-tooltip location="bottom" v-if="tooltip">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props">fa-question-circle</v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-list density="compact">
          <v-list-item v-for="item in items" :key="item.name" :value="item" @click="handleItemClick(item)">
            <template v-slot:prepend>
              <v-list-item-action>
                <v-icon>fas {{ item.icon }}</v-icon>
              </v-list-item-action>
            </template>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BaseClickList',
  props: {
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['dialog'],
  setup(_props, { emit }) {
    const router = useRouter()
    const selected = ref(null)

    const go = (route, param) => {
      if (param) {
        router.push({ name: route, params: { filename: param } })
      } else {
        router.push({ name: route })
      }
    }

    const handleItemClick = (item) => {
      emit('dialog', item.name, item, () => {})
    }

    return {
      selected,
      go,
      handleItemClick
    }
  }
})
</script>