<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary">
            <v-toolbar-title>
              <v-icon icon="fas fa-cogs" size="large" class="mr-2" />
              Chomp Admin Login
            </v-toolbar-title>

            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="fas fa-question"
                  size="large"
                  target="_blank"
                />
              </template>
              <span>Forgot Password?</span>
            </v-tooltip>
          </v-toolbar>
          <v-form v-model="valid" @submit.prevent="login">
            <v-card-text>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                prepend-icon="fas fa-user"
                id="email"
                name="email"
                label="Email"
                type="text"
                required
                @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                v-model="password"
                prepend-icon="fas fa-lock"
                id="password"
                name="password"
                label="Password"
                type="password"
                required
                @keyup.enter="login"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                color="primary"
                type="submit"
              >
                <v-icon icon="fas fa-sign-in-alt" size="small" class="mr-2" />
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@/composables/useAuth';

const router = useRouter();
const auth = useAuth();

const valid = ref(true);
const email = ref('');
const password = ref('');

const emailRules = [
  v => !!v || 'E-mail is required',
  v => /.+@.+/.test(v) || 'E-mail must be valid'
];

const login = async () => {
  try {
    await auth.login(email.value, password.value);
    console.log('Login completed, isAuthenticated:', auth.isAuthenticated.value, 'hasAccessToken:', !!auth.state.accessToken);
    if (auth.isAuthenticated.value && auth.state.accessToken) {
      router.push('/');
    } else {
      console.error('Authentication successful, but isAuthenticated or accessToken is falsy');
    }
  } catch (e) {
    console.error('Login failed:', e);
    // Handle login error (e.g., show an error message to the user)
  }
};

// Watch for changes in isAuthenticated and accessToken
watch(() => [auth.isAuthenticated.value, auth.state.accessToken], ([newIsAuthenticated, newAccessToken]) => {
  console.log('Auth state changed:', { isAuthenticated: newIsAuthenticated, hasAccessToken: !!newAccessToken });
  if (newIsAuthenticated && newAccessToken) {
    router.push('/');
  }
});
</script>