<template>
  <div class="text-center pa-4">
    <v-btn icon @click="dialog = true">
      <v-icon>fas fa-file-export</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" :scrollable="true" max-width="500">
      <v-card :title="'Exporting ' + data.length + ' Records'">
        <v-card-text>

          <v-card class="pa-2">
            Export Columns
            <v-chip-group v-model="selectedFields" column multiple>
              <v-chip v-for="field in fields" :key="field" :text="field" :value="field" filter></v-chip>
            </v-chip-group>
            <v-divider class="mb-2"></v-divider>

            <v-btn prepend-icon="far fa-check-square" size="small" @click="selectAll()"
              :disabled="selectedFields.length === fields.length" class="mr-2">Select All</v-btn>
            <v-btn prepend-icon="far fa-square" size="small" @click="selectNone()"
              :disabled="!selectedFields.length">Select None</v-btn>

          </v-card>
        </v-card-text>
        <v-divider></v-divider>

        <template v-slot:actions>
          <v-btn @click="dialog = false">Close</v-btn>
          <download-csv v-show="selectedFields.length > 0" :data="data" :fields="selectedFields"
            name="exported-data.csv">
            <v-btn color="primary">Export CSV</v-btn>
          </download-csv>
          <v-btn color="primary" v-show="selectedFields.length === 0" disabled>Export CSV</v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import JsonCSV from 'vue-json-csv';

export default defineComponent({
  components: {
    'download-csv': JsonCSV
  },
  props: {
    data: {
      type: [Array, null],
      required: true,
      default: () => []
    }
  },
  setup(props) {
    const dialog = ref(false);
    const fields = ref([]);
    const selectedFields = ref([]);

    watch(() => props.data, (newData) => {
      console.log('Data updated:', newData.length, 'records');
      fields.value = getFields(newData);
      selectedFields.value = [...fields.value];
    }, { deep: true });

    const getFields = (data) => Object.keys(data[0] || {});

    const toggleField = (field) => {
      const index = selectedFields.value.indexOf(field);
      if (index > -1) {
        selectedFields.value.splice(index, 1);
      } else {
        selectedFields.value.push(field);
      }
    };

    const selectAll = () => {
      selectedFields.value = [...fields.value];
    }
    const selectNone = () => {
      selectedFields.value = [];
    }

    onMounted(() => {
      if (props.data && props.data.length > 0) {
        fields.value = getFields(props.data);
        selectedFields.value = [...fields.value];
      }
    });

    return {
      dialog,
      fields,
      selectedFields,
      toggleField,
      selectAll,
      selectNone,
    };
  }
});
</script>
