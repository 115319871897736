<template>
  <!-- BANNER/DISPLAY -->
  <v-container class="px-1" fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="1" class="rounded-sm">
          <v-select
            :label="label"
            :items="items"
            :readonly="readonly"
            v-model="selected"
            item-title="name"
            hide-details
          ></v-select>
        </v-card>
      </v-col>
    </v-row>
    <!-- <pre>selected: {{ selected }}</pre>
    <pre>defaultValue: {{ defaultValue }}</pre> -->
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      selected: this.defaultValue
    }
  },
  props: {
    label: {
      type: String
    },
    tooltip: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    items: {
      type: Array
    },
    defaultValue: {
      type: [Boolean, String, Number, Array]
    }
  },
  watch: {
    defaultValue: {
      handler(val) {
        this.selected = val
      }
    },
    selected: {
      handler(val) {
        this.$emit('selected', val)
      }
    }
  }
}
</script>