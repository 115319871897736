import { createApp } from 'vue'
import App from './App.vue'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import VueClipboard from 'vue3-clipboard'
import JsonCSV from 'vue-json-csv'

import { useAppRouter } from '@/composables/useRouter'
import { authPlugin } from '@/composables/useAuth'

import vuetify from './plugins/vuetify'

const app = createApp(App)
const router = useAppRouter()

// Register base components
const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )
  app.component(componentName, componentConfig.default || componentConfig)
})

app.use(vuetify)
app.use(router)
app.use(authPlugin)
app.use(VueClipboard)
app.component('downloadCsv', JsonCSV)

app.config.globalProperties.$error = new Error()

app.mount('#app')