<template>
    <!-- BUTTON -->
    <v-container class="pa-2 my-3" fluid>
        <v-btn class="mb-4" color="primary"
            rounded
            :loading="loading"
            :disabled="loading"
            :readonly="readonly"
            @click="clicked">
            <v-icon v-if="icon" class="pr-3">fas {{ icon }}</v-icon> 
            {{ label }}
        </v-btn>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                selected: false
            }
        },
        props: {
            label: {
                type: String
            },
            icon: {
                type: String
            },
            readonly: {
                type: Boolean
            },
            loading: {
                type: Boolean
            }
        },
        methods: {
            clicked() {
                this.$emit('clicked', true)
            }
        }
    }
</script>
