<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-item
                v-for="(item, index) in steps"
                :key="index"
                :title="item.title"
                :value="index+1"
                editable
            ></v-stepper-item>
        </v-stepper-header>
        <v-stepper-window>
            <v-stepper-window-item
                v-for="(item, index) in steps"
                :key="index"
                :title="item.title"
                :value="index+1"
                editable
            >
            <h2>{{  item.title }}</h2>
            <div>
                <div v-if="item.list">
                    <BaseList 
                        :id="id" 
                        :page="page"
                        :title="item.title" 
                        :list="item.list" 
                        @data="onApplicationDataUpdate" 
                        @dialog="dialog" 
                        @error="error">
                    </BaseList>
                </div>
                <div v-else-if="item.table">
                    <BaseTablePage 
                        :id="id" 
                        :title="item.title" 
                        :table="item.table" 
                        @data="onApplicationDataUpdate" 
                        @dialog="dialog"
                        @error="error">
                    </BaseTablePage>
                </div>
                <div v-else-if="item.form">
                    <BaseForm 
                        :id="id" 
                        :title="item.title" 
                        :form="item.form"
                        :submit="submitForm[index]"
                        :noButtons="true"
                        @formSubmitted="formSubmitted"
                        @data="onApplicationDataUpdate" 
                        @dialog="dialog" 
                        @addOrModify="setAddOrModify"
                        @error="error">
                    </BaseForm>
                </div>
                <div v-else-if="item.steps">
                    <BaseStepper 
                        :id="id" 
                        :title="item.title" 
                        :steps="item.steps" 
                        @data="onApplicationDataUpdate" 
                        @dialog="dialog"
                        @error="error">
                    </BaseStepper>
                </div>
                <div v-else-if="item.graph">
                    <BaseGraph 
                        :id="id" 
                        :title="item.title" 
                        :graph="item.graph" 
                        @data="onApplicationDataUpdate"
                        @dialog="dialog"
                        @error="error">
                    </BaseGraph>
                </div>
                <div v-else>
                    <BasePage 
                        :id="id" 
                        :title="item.title" 
                        :page="item" 
                        @data="onApplicationDataUpdate"
                        @dialog="dialog"
                        @error="error">
                    </BasePage>
                </div>
            </div>
            </v-stepper-window-item>
        </v-stepper-window>
        <v-stepper-actions
            next-text="Next with Save"
            color="primary"
            :disabled="disabled"
            @click:next="next"
            @click:prev="prev"
        ></v-stepper-actions>
    </v-stepper>
</template>  

<script>

export default {
    data() {
    return {
      step: 1,
      submitForm: {
        type: Array
      }
    };
  },
    props: {
        steps: {
            type: Array
        },
        title: {
            type: String
        },
        id: {
            type: String
        }
    },
    methods: {
        formSubmitted(state) {
            if (state) {
                let index = this.step - 1
                this.submitForm[index] = false
                this.step++
            }
        },
        next() {
            let index = this.step - 1
            if (typeof this.steps[index].form !== 'undefined') {
                // this is a form so we should consider submitting the form, or not
                if (this.steps[index].form.noactions) {
                    this.step++
                } else {
                    this.submitForm[index] = 1
                }
            }
        },
        prev() {
            this.step--
        },
        dialog(message, title, close) {
            if (title == "Updated!") {
                this.$emit('dialog', message, title)
            } else {
                this.$emit('dialog', message, title, close)
            }
            
        },
        error(e) {
            this.$emit('error', e)
        },
        setAddOrModify(value) {
            this.$emit('addOrModify', value)
        },
        onApplicationDataUpdate(data) {
            this.$emit('data', data)
        }
    },
    computed: {
      disabled () {
        return this.step === 1 ? 'prev' : this.step === this.steps.length ? 'next' : undefined
      },
    }
}

</script>

