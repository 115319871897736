import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import Page from '../views/PageView.vue'
import Login from "../views/LoginView.vue"
import PreferencesPage from "@/views/PreferencesView.vue"

export function useAppRouter() {
  const auth = useAuth()

  const routes = [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: '/',
      component: Page,
      props: true
    },
    {
      path: '/page/:page',
      component: Page,
      props: true
    },
    {
      path: '/page/:page/:id',
      component: Page,
      props: true
    },
    {
      path: '/:page',
      component: Page,
      props: true
    },
    {
      path: '/:page/:id',
      component: Page,
      props: true
    },
    {
      path: '/preferences',
      name: 'Preferences',
      component: PreferencesPage
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes: routes
  })

  router.beforeEach((to, from, next) => {
    const isAuthenticated = auth.isAuthenticated.value
    const hasAccessToken = !!auth.state.accessToken

    // console.log('Navigation guard triggered', { 
    //   to, 
    //   from, 
    //   isAuthenticated,
    //   hasAccessToken
    // });

    if (to.name === 'login') {
      // console.log('Allowing access to login page');
      next()
    } else if (isAuthenticated && hasAccessToken) {
      // console.log('User is authenticated, allowing access');
      next()
    } else {
      // console.log('User is not authenticated, redirecting to login');
      next('/login')
    }
  })

  return router
}
