<template>
  <div>
    <div v-if="pages[page]['list']">
      <BaseList :id="id" :page="page" :list="pages[page]['list']" @data="onApplicationDataUpdate" @dialog="dialog" @error="error">
      </BaseList>
    </div>
    <div v-else-if="pages[page]['table']">
      <BaseTablePage :id="id" :title="pageTitle" :table="pages[page]['table']" @data="onApplicationDataUpdate" @dialog="dialog"
        @error="error"></BaseTablePage>
    </div>
    <div v-else-if="pages[page]['form']">
      <BaseForm :id="id" :title="pageTitle" :form="pages[page]['form']" @data="onApplicationDataUpdate" @dialog="dialog" @addOrModify="setAddOrModify"
        @error="error"></BaseForm>
    </div>
    <div v-else-if="pages[page]['steps']">
      <BaseStepper :id="id" :title="pageTitle" :steps="pages[page]['steps']" @data="onApplicationDataUpdate" @dialog="dialog" @addOrModify="setAddOrModify"
        @error="error"></BaseStepper>
    </div>
    <div v-else-if="pages[page]['graph']">
      <BaseGraph :id="id" :title="pageTitle" :graph="pages[page]['graph']" @data="onApplicationDataUpdate" @dialog="dialog"
        @error="error"></BaseGraph>
    </div>
    <div v-else>
      <BasePage :id="id" :page="pages[page]['page']" @data="onApplicationDataUpdate" @error="error"></BasePage>
    </div>
  </div>
</template>

<script>
import config from '../config.js'
import { common } from '../mixins/common'
export default {
  mixins: [common],
  data() {
    return {
      pages: config,
      addOrModify: ''
    }
  },
  props: {
    page: {
      type: String,
      default: function () {
        for (var a_page in config) {
          if (typeof config[a_page].home !== 'undefined' && config[a_page].home == true) {
            return a_page
          }
        }
      }
    },
    id: {
      type: String
    }
  },
  watch: {
    '$route'(to, from) {
      this.from = from
    }
  },
  computed: {
    pageTitle: function () {
      let titlePrePend = ''
      if (this.pages[this.page]['form']) {
        titlePrePend = this.addOrModify
      }
      if (typeof this.pages[this.page].title !== "undefined") {
        return this.capitalizeFirstLetter(titlePrePend + this.pages[this.page].title)
      } else {
        return this.capitalizeFirstLetter(titlePrePend + this.page)
      }
    }
  },
  methods: {
    dialog(message, title, close) {
      this.$emit('dialog', message, title, close)
    },
    setAddOrModify(value) {
      this.addOrModify = value + " "
    },
    onApplicationDataUpdate(data) {

      var pageUrls = [];
      if (typeof this.pages[this.page].table !== "undefined") {
        pageUrls.push(this.pages[this.page].table['url'])
      }
      if (typeof this.pages[this.page].form !== "undefined") {
        pageUrls.push(this.pages[this.page].form['url'])
      }
      if (typeof this.pages[this.page].page !== "undefined") {
        pageUrls.push(this.pages[this.page].page['url'])
      }
      if (typeof this.pages[this.page].graph !== "undefined") {
        for (var item in this.pages[this.page].graph['data']) {
          pageUrls.push(this.pages[this.page].graph['data'][item].url)
        }
      }

      // Pass data to parent component
      this.$emit('onApplicationDataUpdate', {applicationData: data, pageUrls})
    }
  },
  created() {
    if (typeof this.pages[this.page] === 'undefined') {
      this.error(new Error("Page '" + this.page + "' does not exist.  Please check your configuration."))
    }
  }
}
</script>