<template>
  <div>
    <v-dialog
      :model-value="showDialog"
      transition="dialog-top-transition"
      :scrim="false"
      persistent
      width="600"
      scrollable max-width="600"
    >
      <v-card>
        <!-- Title -->
        <v-card-title v-if="title" class="text-h5 bg-grey">
          {{ title }}
        </v-card-title>
        <!-- Simple Message -->
        <v-card-text v-if="dialogMessage">
          <div class="text-center text-h5 pa-8">
            <span v-html="dialogMessage"></span>
          </div>
        </v-card-text>
        <!-- Object Dump -->
        <v-card-text v-if="dialogObject" style="height: 400px;">
          <vue-json-pretty :data="dialogObject"></vue-json-pretty>
        </v-card-text>
        <v-divider></v-divider>
        <!-- File / image render -->
        <v-card-text v-if="dialogImage" style="height: 40vh;">
          <BaseSecureImage :imageUrl="dialogImage.name"></BaseSecureImage>
        </v-card-text>
        <v-divider></v-divider>
        <!-- Progress Indicator -->
        <v-progress-linear v-if="autoClose"
          :buffer-value="progress"
          :indeterminate="indeterminate"
          class="mb-0"
          reverse
          stream
          height="20"
        ></v-progress-linear>
        <!-- Buttons -->
        <v-card-actions v-if="choice" class="justify-center">
          <v-btn
            color="primary"
            @click="closeDialog(true)"
          >
          OK
          </v-btn>
          <v-btn
            color="primary"
            @click="closeDialog(false)"
          >
          Cancel
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="confirm" class="justify-center">
          <v-btn
            color="primary"
            @click="closeDialog(false)"
          >
          Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css';
  export default {
    data () {
      return {
        confirm: false,
        choice: false,
        autoClose: false,
        dialogMessage: '',
        dialogObject: '',
        dialogImage: null,
        indeterminate: false,
        valueDeterminate: 800,
        increment: 100,
        multiplier: 10,
        progress: 0
      }
    },
    props: {
      showDialog: {
        type: [ Boolean, Number ],
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      message: {
        type: [ String, Object ],
        default: ''
      },
      close: {
        type: [ Function, Number, String, Boolean ]
      }
    },
    components: {
      VueJsonPretty
    },
    watch: {
      showDialog: {
        handler () {
          this.setBehavior()
        }
      },
      message: {
        handler (val) {
          this.dialogMessage = null;
          this.dialogImage = null;
          this.dialogObject = null;
          if (typeof val === "object") {
            if (Object.prototype.hasOwnProperty.call(val, 'name') && val.name.includes('/download')) {
              // we have a file download, so lets download it
              this.dialogImage = val;
            } else {
              // we have an object for a message, so lets display that 
              this.dialogObject = val
            }
          } else if (typeof val === 'string' || val instanceof String) {
            // We have a nice mesage so lets show that
            this.dialogMessage = val
          } else {
            // we have a title, but not a message!
            // the title IS the message
            this.dialogMessage = this.title
          }
        }
      }
    },
    methods: {
      closeDialog(followCallback) {
        // Options after closing
        //
        // If a callback has been supplied, we need to
        // execute this as a priority.  The callback 
        // should probably finish with one of these things
        //
        // Refresh the current page
        // this.$router.go()
        //
        // Go back to the last page
        // this.$router.go(-1);
        //
        // Go to the last route that was loaded
        // (path needs to come in from the caller of the dialog)
        //
        if (followCallback) {
          this.close()
        } else {
          this.$router.go()
        }
        this.$emit('show', false)
      },
      moveProgress() {
        this.progress = this.progress + (this.increment/this.valueDeterminate*100)
        if (this.progress > 100) {
          this.closeDialog(false)
        } else {
          setTimeout(this.moveProgress, this.increment)
        }
      },
      setBehavior() {
        this.confirm = false
        this.choice = false
        this.autoClose = false
        this.indeterminate = false
        this.progress = 0
        if (this.close instanceof Function) {
          // give the user a choice
          // OK will run the callback stored in this.close
          // Cancel will reload the page
          this.choice = true
        } else if (typeof this.close !== "undefined") {
          // delay for the amount of this.close
          // and then close the dialog and reload the page
          // if this.close is zero then dialog remains open
          this.autoClose = true
          if (this.close === parseInt(this.close)) {
            if (this.close == 0) {
              this.indeterminate = true
            } else {
              this.valueDeterminate = this.close*this.increment*this.multiplier
              this.moveProgress()
            }
          } else {
            // default if close is set to anything other than a number
            // or callback function is an auto-close
            this.moveProgress()
          }
        } else {
          // give the user a confirm button to close the
          // dialog and reload the page
          this.indeterminate = false
          this.confirm = true
        }
      }
    }
  }
</script>