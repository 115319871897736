<template>
  <!-- Toggle Button -->
  <v-container class="pa-2 my-3" fluid>
    <v-switch hide-details v-model="selected" :readonly="readonly" color="primary">
      <template v-slot:label>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <span v-bind="props">{{ label }}</span>
          </template>
          {{ tooltip }}
        </v-tooltip>
      </template>
    </v-switch>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      selected: false
    }
  },
  props: {
    label: {
      type: String
    },
    tooltip: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    onValue: {
      type: [String, Number, Boolean]
    },
    offValue: {
      type: [String, Number, Boolean]
    },
    defaultValue: {
      type: [String, Number, Boolean]
    }
  },
  watch: {
    defaultValue: {
      handler(val) {
        if (typeof this.onValue == 'string') {
          if (val == this.onValue) {
            this.selected = true
          } else {
            this.selected = false
          }
        } else if (typeof this.offValue == 'string') {
          if (val == this.offValue) {
            this.selected = false
          } else {
            this.selected = true
          }
        } else {
          this.selected = val
        }
      }
    },
    selected: {
      handler(val) {
        if (val) {
          if (typeof this.onValue == 'string') {
            this.$emit('checked', this.onValue)
          } else {
            this.$emit('checked', true)
          }
        } else {
          if (typeof this.offValue == 'string') {
            this.$emit('checked', this.offValue)
          } else {
            this.$emit('checked', false)
          }
        }
      }
    }
  },
  mounted() {
    if (typeof this.onValue == 'string' && this.defaultValue == this.onValue) {
      this.selected = true
    } else if (typeof this.offValue == 'string' && this.defaultValue == this.offValue) {
      this.selected = false
    } else {
      this.selected = this.defaultValue
    }
  }
}
</script>
