// useHttpCommon.js
import axios from 'axios'
import constants from '../constants'

export function useHttpCommon() {
  const HTTP_AUTH = axios.create({
    baseURL: constants.authTokenEndpoint,
    timeout: constants.connectionTimeout,
    method: 'post',
    responseType: 'json'
  })

  const ajax = axios.create({
    timeout: constants.connectionTimeout,
    responseType: 'json'
  })

  const ajaxFile = axios.create({
    timeout: constants.connectionTimeout,
    responseType: 'blob'
  })

  ajax.CancelToken = axios.CancelToken
  ajax.isCancel = axios.isCancel

  ajaxFile.CancelToken = axios.CancelToken
  ajaxFile.isCancel = axios.isCancel

  // Interceptor for ajax
  ajax.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('access_token')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(Error(error))
    }
  )

  // Interceptor for ajaxFile
  ajaxFile.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('access_token')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(Error(error))
    }
  )

  return {
    HTTP_AUTH,
    ajax,
    ajaxFile
  }
}

// If you want to keep the default export
export default useHttpCommon().ajax