<template>
  <v-expansion-panels class="mb-5" v-model="panelExpanded">
    <v-expansion-panel>
      <v-expansion-panel-title>{{ config.heading + (formattedDate ? ': ' + formattedDate : '') }}</v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-container>
          <v-row>
            <v-col sm="12" md="4">
              <p class="text-body-1" v-if="config.subheading">{{ config.subheading }}</p>
            </v-col>
            <v-col sm="12" md="3">
              <v-date-input v-model="selectedDateRange" :label="config.heading" max-width="368" multiple="range" variant="outlined" @update:modelValue="onApplyFilter" placeholder="dd/mm/yyyy - dd/mm/yyyy"></v-date-input>
            </v-col>
            <v-col sm="12" md="3">
              <v-select label="Date Range Shortcuts" prepend-icon="fas fa-list-ol" :items="config.shortcuts"
                @update:modelValue="onShortcutClick" v-model="selectedShortcut" variant="outlined"></v-select>
            </v-col>
            <v-col sm="12" md="2" align="right">
              <v-btn @click="onClearFilterClick" class="mr-5">Clear Filter</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { ref, computed, watch } from 'vue'
import moment from 'moment'
import { useUserPreferences } from '@/composables/userPreferences'

export default {
  name: 'BaseTableFilterDateRange',
  props: {
    config: {
      type: Object,
      required: true
    },
  },
  emits: ['update:dateRange'],
  setup(props, { emit }) {
    const { preferences, updatePreference } = useUserPreferences()

    const selectedDateRange = ref([]);
    const panelExpanded = ref(preferences.value.baseTableFilterDateRangeExpansionPanelState ? 0 : undefined); // 0 & undefined don't play nicely with the preferences object, so convert from boolean
    const selectedShortcut = ref(null);

    const formattedDate = computed(() => {
      if (!selectedDateRange.value.length) {
        return null;
      }
      return `${moment(selectedDateRange.value[0]).format('DD/MM/YYYY')} - ${moment(selectedDateRange.value[selectedDateRange.value.length - 1]).format('DD/MM/YYYY')}`;
    })

    watch(panelExpanded, (current) => {
      // 0 & undefined don't play nicely with the preferences object, so convert to boolean
      updatePreference('baseTableFilterDateRangeExpansionPanelState', current === 0 ? true : false);
    })

    const onShortcutClick = (daysDifference) => {
      selectedShortcut.value = daysDifference;
      const startDate = moment().subtract(daysDifference, 'days').startOf('day');
      const endDate = moment().endOf('day');
      const dateArray = [];
      let currentDate = startDate.clone();

      while (currentDate <= endDate) {
        dateArray.push(currentDate.toDate());
        currentDate.add(1, 'days');
      }

      // Ensure endDate is included
      if (dateArray[dateArray.length - 1].getTime() !== endDate.toDate().getTime()) {
        dateArray.push(endDate.toDate());
      }

      selectedDateRange.value = dateArray;
      onApplyFilter();
    }

    const onClearFilterClick = () => {
      selectedDateRange.value = [];
      selectedShortcut.value = null;
      emit('update:dateRange', {})
    }
    const onApplyFilter = () => {
      if (!selectedDateRange.value.length) {
        console.log('No date range specified, doing nothing');
        return;
      }

      const filters = {
        [props.config.propertyName]: props.config.filterKey,
        [props.config.startDate]: moment.parseZone(selectedDateRange.value[0]).utc(true).format(),
        [props.config.endDate]: moment.parseZone(selectedDateRange.value[selectedDateRange.value.length - 1]).utc(true).format()
      };

      emit('update:dateRange', filters)
    }

    return {
      selectedShortcut,
      formattedDate,
      panelExpanded,
      selectedDateRange,
      onShortcutClick,
      onApplyFilter,
      onClearFilterClick
    }
  }
}
</script>
