<template>

  <v-container>
    <v-row>
      <v-col>
        <v-banner :icon="icon" :text="bannerLabel">
          {{ bannerText }}
          <v-tooltip v-if="tooltipText" activator="parent" location="start">{{ tooltipText }}</v-tooltip>
          <template v-if="route" v-slot:actions>
            <v-btn @click="handleClick">More</v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { common } from '../mixins/common'

export default {
  mixins: [common],
  props: {
    route: {
      type: String
    },
    bannerIcon: {
      type: [String, Number, Object]
    },
    bannerLabel: {
      type: String
    },
    bannerText: {
      type: [String, Number]
    },
    tooltipText: {
      type: [String]
    }
  },
  computed: {
    icon: function () {
      if (typeof this.bannerIcon === 'object' && this.bannerIcon !== null) {
        if (Object.prototype.hasOwnProperty.call(this.bannerIcon, this.bannerText)) {
          return `fas ${this.bannerIcon[this.bannerText]}`;
        }
      }
      return this.bannerIcon ? `fas ${this.bannerIcon}` : null;
    }
  },
  methods: {
    handleClick() {
      if (this.route) {
        this.$router.push(this.route);
      }
    }
  }
}
</script>

<style>
.v-banner-text {
  min-width: 200px;
}
</style>