<template>
  <v-breadcrumbs :items="breadcrumbs" class="breadcrumbs"></v-breadcrumbs>
</template>

<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useHttpCommon } from '@/composables/useHttpCommon'
import config from '../config';

export default {
  setup() {
    const route = useRoute();
    const breadcrumbs = ref([]);

    watch(() => route.path, (newPath) => {
      setBreadcrumbs(newPath);
    })

    const setBreadcrumbs = (newPath) => {
      const { ajax } = useHttpCommon()
      const key = (newPath.split('/')[1] || 'home');
      const parent = config[key]?.subtitle?.link.split('/')[1] || '';
      const parentLink = config[key]?.subtitle?.link || '';

      const crumbs = [];

      if (parent) {
        crumbs.push({
          title: parent.replaceAll('_', ' '),
          href: parentLink.replace('{id}', route.params.id)
        })
        crumbs.push({
          title: key.replaceAll('_', ' ')
        })
      } else {
        crumbs.push({
          title: key.replaceAll('_', ' ')
        })
      }

      breadcrumbs.value = crumbs;

      if (config[key] && config[key].subtitle && config[key].subtitle.url) {
        ajax.get(config[key].subtitle.url.replace('{id}', route.params.id))
          .then(response => {
            breadcrumbs.value.push({ title: response.data.name })
          })
          .catch(e => {
            console.log(e)
          })
      } else if (route?.query?.add) {
        breadcrumbs.value.push({ title: 'Add New' })
      } else if (config[key] && config[key].form && config[key].form.url) {
        ajax.get(config[key].form.url.replace('{id}', route.params.id))
          .then(response => {
            const title = response.data.name ? response.data.name : response.data?.profile?.name;
            breadcrumbs.value.push({ title })
          })
          .catch(e => {
            console.log(e)
          })
      }

    }

    return {
      breadcrumbs,
    }
  }
}
</script>

<style scoped>
/* Add any custom styles here */
.breadcrumbs {
  text-transform: capitalize;
}
</style>