<template>
    <!-- RADIO BUTTONS -->
    <v-container class="pa-2 my-3" fluid>
        <div slot="label"> 
            {{ label }}
            <v-tooltip location="bottom" v-if="tooltip">
                <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">fas fa-question-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </div>
        <v-row>
            <v-col cols="12" sm="6" md="12">
                <v-radio-group inline
                    v-model="checkedButton"
                    :readonly="readonly"
                    hide-details>
                        <v-checkbox class="pa-2" v-for="item in items" :key="item.name"
                            :label="item.name"
                            :value="item.value"
                            :disabled="readonly"
                            :false-icon="readonly ? 'fas fa-minus' : $vuetify.icons.radioOff"
                            :true-icon="readonly ? 'fas fa-minus' : $vuetify.icons.radioOn"
                        ></v-checkbox>
                </v-radio-group>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                checkedButton: ""
            }
        },
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            readonly: {
                type: Boolean
            },
            items: {
                type: Array
            },
            defaultValue: {
                type: [ Boolean, String, Number, Array ]
            }
        },
        watch: {
            defaultValue: {
                handler (val) {
                    this.checkedButton = val
                }
            },
            checkedButton: {
                handler (val) {
                    this.$emit('checked', val)
                }
            }
        },
        mounted() {
            this.checkedButton = this.defaultValue
        }
    }
</script>
