<template>
    <v-list nav>
      <v-list-item
        v-for="item in navItems"
        style="text-transform: capitalize;"
        :prepend-icon="'fas ' + item.icon"
        :value="item.title"
        :key="item"
        @click="nav(item.path)"
        color="primary"
        :active="item.path === $route.fullPath"
        >{{ item.title }}</v-list-item>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-list-item prepend-icon="fas fa-info" @click="nav('/preferences')">{{ version + environment }}</v-list-item>
    </v-list>
</template>
<script>
import config from '../config.js';
import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import { useAuth } from '@/composables/useAuth'

export default defineComponent({
  setup() {
    const auth = useAuth();
    const version = `${process.env.VUE_APP_VERSION}`;
    const environment = process.env.VUE_APP_ENVIRONMENT === 'production' ? '' : `-${process.env.VUE_APP_ENVIRONMENT}`;
    const router = useRouter();
    const navItems =
      Object.entries(config).filter(([_key, value]) => Object.hasOwnProperty.call(value, 'nav') && value.nav
      ).map(page => ({
        title: page[0].replace('_', ' '),
        path: `/${page[0]}`,
        icon: page[1]?.icon || 'fa-cloud'
      }));

    navItems.push({
        title: 'Logout',
        path: 'logout',
        icon: 'fa-sign-out-alt'
      })

    const nav = (path) => {
      switch (path) {
        case 'logout':
          auth.logout()
          router.push({ path: '/login' })
          break;

        default:
          router.push({ path: path })
          break;
      }
    };

    return {
      navItems,
      nav,
      version,
      environment,
    }
  }
})
</script>


<!-- <template>
  <v-col>
    <v-navigation-drawer
      :rail.sync="mini"
      rail-width="78"
      permanent>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title @click="nav(path)">
          <v-icon size="large">fas {{icon}}</v-icon>&nbsp;{{ title }}
        </v-toolbar-title>
        <v-btn icon @click.stop="mini = !mini"><v-icon>fas fa-chevron-left</v-icon></v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list density="compact" class="pt-0">
        <v-list-item
            v-for="item in pages"
            :key="item.title"
            @click="nav(item.path)"
        >
          <v-list-item-action>
            <v-icon>fas {{ item.icon }}</v-icon>
          </v-list-item-action>
          
          
            <v-list-item-title>{{ capitalizeFirstLetter(item.title) }}</v-list-item-title>
          
        </v-list-item>

      </v-list>
      <div class="text-caption text--disabled light">content: {{ contentVersion }}</div>
      <div class="text-caption text--disabled light">software: {{ softwareVersion }}{{ env === 'production' ? '' : `-${env}` }}</div>
    </v-navigation-drawer>
</v-col>
</template>

<style scoped>
  .tile {
    margin: 2px;
    border-radius: 0px;
    background: primary;
  }
</style>

<script>
  import config from '../config.js'
  import { common } from '../mixins/common'
  export default {
    mixins: [ common ],
    name: 'BaseNav',
    data () {
      return {
        pages: [],
        right: null,
        sheet: false,
        title: "Admin",
        icon: "fa-user-cog",
        path: "/",
        contentVersion: "n/a",
        mini: false,
        env: process.env.VUE_APP_ENVIRONMENT
      }
    },
    props: {
      softwareVersion: {
        type: String,
        default: 'n/a'
      }
    },
    mounted: function () {
      if (config['nav']) {
        if (config['nav'].title) {
          this.title = config['nav'].title
        }
        if (config['nav'].icon) {
          this.icon = config['nav'].icon
        }
        if (config['nav'].path) {
          this.path = config['nav'].path
        }
        if (config['nav'].version) {
          this.contentVersion = config['nav'].version
        }
      }
      for (var page in config) {
        if (Object.prototype.hasOwnProperty.call(config[page], 'nav') && config[page]['nav'] == true) {
          config[page]['title'] = this.capitalizeFirstLetter(page)
          config[page]['path'] = "/" + page
          if (!(Object.prototype.hasOwnProperty.call(config[page],'icon'))) {
            config[page]['icon'] = 'fa-cloud'
          }
          this.pages.push(config[page])
        }
      }
      this.pages.push({ title: 'Logout', icon: 'fa-sign-out-alt', path: 'logout' })
    },
    methods: {
      nav(path) {
        if (path == 'logout') {
          this.$auth.logout()
          this.$router.push({ path: '/login' })
        } else {
          this.$router.push({ path: path })
        }
      }
    }
  }
</script> -->
