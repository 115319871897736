<template>
  <v-card border class="mx-auto">
    <v-card-text>
      <h5 class="text-h5 pa-2">{{ graph.title }}</h5>
      <GChart
        v-if="hasData"
        :type="graph.type"
        :data="chartData"
        :options="chartOptions"
      />
      <v-alert class="ma-10" v-else text="No data returned from API" type="info"></v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import { common } from '../mixins/common'
  import { GChart } from 'vue-google-charts/legacy'
  export default {
    mixins: [ common ],
    components: {
      GChart
    },
    data: function () {
      return {
        hasData: false,
        chartData: [],
        chartOptions: {
          legend: { position: 'bottom' },
          height: 600
        }
      }
    },
    props: {
      id: {
          type: String
      },
      graph: {
          type: Object
      }
    },
    mounted: function () {
      this.checkUrlParameters()
      this.getData()
    },
    methods: {
      checkUrlParameters() {
        // this will insert the key/value pairs from the url into the data object
        for (var key in this.$route.query) {
          this.data[key] = this.$route.query[key]
        }
      },
      parseResponse(data, rule) {
        let parsed = []
        for (var index in data) {
          let item = data[index]
          if (typeof item[rule] !== 'undefined') {
            parsed.push(item[rule])
          }
        }
        return parsed
      },
      getData() {
        if (typeof this.graph.data !== 'undefined') {
          let rules = []
          let legend = []
          let graphUrls = []
          for (var row in this.graph.data) {
            let column = this.graph.data[row]
            var parameters = { id: this.id }
            if (typeof column.url !== 'undefined') {
              if (typeof this.graph.count !== 'undefined') {
                if (typeof this.graph.count.default !== 'undefined') {
                  parameters.count = this.graph.count.default
                }
              }
              let url = this.url(column.url, parameters)
              graphUrls.push(url)
              rules.push(column.parse_rule)
              legend.push(column.legend)
            }
          }
          var promise = new Promise( (resolve, reject) => {
            this.getDataFromURLs(graphUrls).then( (results) => {
              let parsed = []
              for (let index in results) {
                parsed[index] = this.parseResponse(results[index].data, rules[index])
              }
              resolve(parsed)
            }, (error) => {
              reject(error)
            })
          })
          promise.then((values) => {
            for (var index1 in values[0]) {
              let row = []
              for (let index2 in values) {
                row.push(values[index2][index1])
              }
              this.chartData.unshift(row)
            }
            this.hasData = !!this.chartData.length;
            this.chartData.unshift(legend)
            this.$emit('data', { 'data': this.chartData })
          });
        }
      }
    }
  }
</script>
